import React from "react";
import { Helmet } from "react-helmet-async";
import "./News.scss";
import { evaluate, string } from "mathjs";

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      equation: "",
      history: [],
    };
    this.handleEquationChange = this.handleEquationChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSolve = this.handleSolve.bind(this);
    this.updateState = this.updateState.bind(this);
    this.clearEquation = this.clearEquation.bind(this);
    this.bsEquation = this.bsEquation.bind(this);
  }

  updateState(newState) {
    this.setState(newState);
  }

  handleEquationChange(e) {
    // console.log(e.target.name)
    var copyHistory = [...this.state.history];
    this.updateState({ equation: e.target.name, history: copyHistory });
    // console.log(this.state.equation)
  }

  handleClick(e) {
    var focus = document.getElementById("mainInput");
    focus.focus();
    var copyEquation = [...this.state.equation];
    copyEquation.push(e.target.name);
    copyEquation = copyEquation.join("");
    console.log(copyEquation);
    var copyHistory = [...this.state.history];
    this.updateState({ equation: copyEquation, history: copyHistory });
    // console.log(e.target.name)
  }

  handleSolve() {
    var copyEquation = this.state.equation;
    var copyHistory = [...this.state.history];
    console.log("history:", this.state.history);
    var res = string(evaluate(copyEquation));
    copyHistory.push(copyEquation + "=" + res);
    // console.log(copyHistory)
    this.updateState({
      equation: res,
      history: copyHistory,
    });
  }

  clearEquation() {
    var copyHistory = this.state.history;
    if (this.state.equation === "") {
      copyHistory = [];
    }
    this.updateState({ equation: "", history: copyHistory });
  }

  bsEquation() {
    var copyEquation = [...this.state.equation];
    copyEquation = copyEquation.slice(0, copyEquation.length - 1).join("");
    var copyHistory = this.state.history;
    this.updateState({ equation: copyEquation, history: copyHistory });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>أخبار مالية</title>
          <meta
            name="description"
            content="الآلة الحاسبة هي جهاز يقوم بعمليات حسابية"
          />
          <link rel="canonical" href="/News" />
        </Helmet>
        <div className="main-app news">
          <h2>أخبار مالية</h2>
          <div className="news__grid">
            <div className="news__item">
              <h2>
                مؤشر ستاندرد آند بورز 500 يسجل أسوأ أسبوع له منذ مارس - 18 أغسطس
                2023
              </h2>
              <img
                src="https://images.pexels.com/photos/325229/pexels-photo-325229.jpeg"
                alt="Stock market chart showing a decline"
                width="300"
              />
              <p>
                <span>
                  في ختام الأسبوع المنتهي في 18 أغسطس، تلقى سوق الأسهم الأمريكية
                  ضربة موجعة.
                </span>
                مؤشر ستاندرد آند بورز 500، الذي يعد مقياسًا هامًا لأداء 500 شركة
                كبرى في الولايات المتحدة، انخفض بنسبة 2.1%، مسجلاً أسوأ أداء
                أسبوعي له منذ مارس الماضي. هذا يعني أن القيمة السوقية لهذه
                الشركات تراجعت بشكل ملحوظ، مما أثار قلق المستثمرين ودفعهم
                للتساؤل عما إذا كان هذا بداية لانهيار كبير في السوق، أم مجرد
                فترة قصيرة من الانخفاض.
                <br />
                <br />
                <strong>
                  ويرى الخبراء أن هذا الانخفاض يعود إلى عدة أسباب، منها:
                </strong>
                <ul>
                  <li>
                    القلق من تباطؤ النمو الاقتصادي العالمي، مما يؤثر على أرباح
                    الشركات الأمريكية.
                  </li>
                  <li>
                    قيام البنوك المركزية، مثل الاحتياطي الفيدرالي الأمريكي، برفع
                    أسعار الفائدة لمكافحة التضخم، مما يجعل الاقتراض أكثر تكلفة،
                    ويؤثر على الشركات والأفراد.
                  </li>
                  <li>
                    التوترات السياسية العالمية، مثل الحرب في أوكرانيا، التي تخلق
                    حالة من عدم اليقين في الأسواق.
                  </li>
                </ul>
                <br />
                هذا الانخفاض الكبير جعل المستثمرين في حالة من الترقب، متسائلين
                عما إذا كان هذا بداية لتراجع كبير في سوق الأسهم، أم مجرد فترة
                قصيرة من الانخفاض.
              </p>
            </div>

            <div className="news__item">
              <h2>
                مجموعة "إيفرغراند" الصينية العملاقة للتطوير العقاري تتقدم بطلب
                لإشهار إفلاسها في الولايات المتحدة - 17 أغسطس 2023
              </h2>
              <img
                src="https://images.pexels.com/photos/323780/pexels-photo-323780.jpeg"
                alt="Bankruptcy court"
                width="300"
              />
              <p>
                <span>
                  في خبر صادم هزّ الأوساط المالية العالمية، أعلنت شركة
                  "إيفرغراند" الصينية، إحدى أكبر شركات التطوير العقاري في الصين،
                  عن تقديمها طلبًا لإشهار إفلاسها في الولايات المتحدة.
                </span>
                تأتي هذه الخطوة بعد سنوات من تراكم الديون على الشركة، والتي
                تجاوزت 300 مليار دولار، مما يجعلها واحدة من أكثر الشركات مديونية
                في العالم.
                <br />
                <br />
                <strong>
                  هذا الإفلاس يمثل ضربة موجعة للاقتصاد الصيني، وذلك للأسباب
                  التالية:
                </strong>
                <ul>
                  <li>
                    كانت إيفرغراند شركة محورية في قطاع العقارات الصيني، ولها
                    مشاريع بناء ضخمة في جميع أنحاء البلاد.
                  </li>
                  <li>
                    يثير إفلاسها مخاوف من تأثير الدومينو على شركات أخرى في
                    القطاع، وربما حتى على البنوك الصينية والعالمية التي قدمت لها
                    قروضًا.
                  </li>
                </ul>
                <br />
                الحكومة الصينية تحاول الآن احتواء هذه الأزمة، لكنها تواجه تحديًا
                كبيرًا، والجميع قلق بشأن تداعيات هذا الإفلاس على الاقتصاد الصيني
                والعالمي.
              </p>
            </div>

            <div className="news__item">
              <h2>
                احتدام المخاوف بشأن تباطؤ النمو الاقتصادي الصيني - 15 أغسطس 2023
              </h2>
              <img
                src="https://images.pexels.com/photos/1450382/pexels-photo-1450382.jpeg"
                alt="Graph showing a downward trend"
                width="300"
              />
              <p>
                <span>
                  في منتصف أغسطس 2023، تصاعدت المخاوف بشأن تباطؤ النمو الاقتصادي
                  الصيني بعد نشر بيانات اقتصادية مخيبة للآمال.
                </span>
                أظهرت هذه البيانات انكماشًا في قطاع الصناعة للشهر الرابع على
                التوالي، وتراجعًا في مبيعات التجزئة والاستثمار في الأصول
                الثابتة. كما ارتفعت معدلات البطالة، خاصة بين الشباب.
                <br />
                <br />
                <strong>
                  هذه الأخبار السلبية تأتي في وقت تواجه فيه الصين تحديات
                  اقتصادية متعددة، منها:
                </strong>
                <ul>
                  <li>
                    أزمة في سوق العقارات، حيث تعاني العديد من شركات البناء
                    الكبرى من ديون ضخمة، وبعضها يواجه خطر الإفلاس.
                  </li>
                  <li>
                    ضعف الطلب العالمي على المنتجات الصينية، مما يؤثر على
                    الصادرات.
                  </li>
                  <li>
                    استمرار تأثير جائحة كورونا، خاصة مع الإغلاقات التي تحدث في
                    بعض المدن الصينية.
                  </li>
                </ul>
                <br />
                الصين، باعتبارها ثاني أكبر اقتصاد في العالم، يؤثر تباطؤ نموها
                على الاقتصاد العالمي بأكمله. وتسعى الحكومة الصينية جاهدة لمعالجة
                هذه المشاكل، لكنها تواجه تحديات كبيرة ومعقدة، مما يجعل المستقبل
                الاقتصادي للبلاد غير واضح المعالم.
              </p>
            </div>

            <div className="news__item">
              <h2>الذهب يصل إلى أعلى مستوى له في شهر - 7 أغسطس 2023</h2>
              <img
                src="https://images.pexels.com/photos/4132438/pexels-photo-4132438.jpeg"
                alt="Gold bars stacked"
                width="300"
              />
              <p>
                <span>
                  في بداية شهر أغسطس، شهدت أسعار الذهب ارتفاعًا ملحوظًا، لتصل
                  إلى أعلى مستوى لها خلال الشهر.
                </span>
                بلغ سعر الأونصة الواحدة من الذهب 1975 دولارًا، مما يعكس زيادة في
                الطلب عليه.
                <br />
                <br />
                <strong>يعزى هذا الارتفاع إلى عدة عوامل، منها:</strong>
                <ul>
                  <li>
                    ضعف الدولار الأمريكي، مما يجعل الذهب أكثر جاذبية للمستثمرين
                    الذين يتعاملون بعملات أخرى.
                  </li>
                  <li>
                    المخاوف بشأن تباطؤ النمو الاقتصادي العالمي، مما يدفع
                    المستثمرين إلى البحث عن ملاذات آمنة.
                  </li>
                  <li>
                    التوترات الجيوسياسية المتصاعدة، التي تزيد من حالة عدم اليقين
                    في الأسواق.
                  </li>
                </ul>
                <br />
                يعتبر الذهب تقليديًا ملاذًا آمنًا في أوقات الأزمات الاقتصادية
                والسياسية، حيث يلجأ إليه المستثمرون لحماية ثرواتهم من التضخم
                وتقلبات الأسواق. وقد أدى ارتفاع أسعاره إلى زيادة الطلب عليه،
                خاصة من قبل المستثمرين في الأسواق الناشئة الذين يبحثون عن
                استثمارات مستقرة في ظل الظروف الاقتصادية الصعبة.
              </p>
            </div>

            <div className="news__item">
              <h2>
                {" "}
                الاحتياطي الفيدرالي الأمريكي يرفع أسعار الفائدة للمرة الحادية
                عشرة على التوالي - 26 يوليو 2023
              </h2>
              <img
                src="https://images.pexels.com/photos/374870/pexels-photo-374870.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Federal Reserve building"
                width="300"
              />
              <p>
                <span>
                  في خطوةٍ متوقعة، قرر الاحتياطي الفيدرالي الأمريكي (البنك
                  المركزي) رفع سعر الفائدة الرئيسي مرة أخرى في 26 يوليو 2023.{" "}
                </span>
                هذه هي المرة الحادية عشرة على التوالي التي يقوم فيها البنك برفع
                الفائدة منذ بداية العام الماضي، في محاولةٍ لكبح جماح التضخم
                المرتفع.
                <br />
                <br />
                <strong>الهدف من رفع الفائدة:</strong>
                <ul>
                  <li>السيطرة على التضخم، أي ارتفاع الأسعار المستمر.</li>
                </ul>
                <br />
                <strong>تأثير رفع الفائدة:</strong>
                <ul>
                  <li>
                    يجعل الاقتراض أكثر تكلفة، مما قد يؤثر على قدرة الأفراد على
                    شراء المنازل والسيارات، ويحد من قدرة الشركات على الاستثمار
                    والتوسع.
                  </li>
                  <li>قد يؤدي إلى تباطؤ النمو الاقتصادي.</li>
                </ul>
                <br />
                أكد الفيدرالي الأمريكي أنه سيواصل مراقبة البيانات الاقتصادية عن
                كثب، وأنه على استعداد لرفع أسعار الفائدة مرة أخرى إذا لزم الأمر
                لضمان عودة التضخم إلى المستويات المستهدفة.
              </p>
            </div>

            <div className="news__item">
              <h2>
                تراجع أسعار الغاز الطبيعي في أوروبا إلى أدنى مستوياتها منذ عام
                2021 - 24 يوليو 2023
              </h2>
              <img
                src="https://cdn.pixabay.com/photo/2016/03/27/18/54/industry-1283758_960_720.jpg"
                alt="Gas pipeline"
                width="300"
              />
              <p>
                <span>
                  في 24 يوليو 2023، شهدت أسعار الغاز الطبيعي في أوروبا انخفاضًا
                  كبيرًا، لتصل إلى أدنى مستوياتها منذ عام 2021.
                </span>
                هذا يعني أن تكلفة الغاز أصبحت أقل بكثير، وهو خبر إيجابي للاقتصاد
                الأوروبي، خاصةً بعد الارتفاعات الحادة التي شهدتها الأسعار في
                الفترة السابقة.
                <br />
                <br />
                <strong>أسباب هذا الانخفاض:</strong>
                <ul>
                  <li>
                    نجاح أوروبا في تنويع مصادر إمدادات الغاز، وتقليل اعتمادها
                    على الغاز الروسي، مما زاد من مرونة السوق.
                  </li>
                  <li>
                    زيادة المعروض من الغاز الطبيعي المسال، الذي يتم استيراده من
                    دول أخرى.
                  </li>
                  <li>
                    الطقس المعتدل الذي ساهم في تقليل الطلب على الغاز للتدفئة.
                  </li>
                </ul>
                <br />
                هذا الانخفاض في أسعار الغاز سيخفف العبء على المستهلكين والشركات
                في أوروبا، ويساعد في خفض معدلات التضخم. ومع ذلك، لا يزال هناك
                بعض القلق بشأن استقرار إمدادات الطاقة في أوروبا في المستقبل،
                خاصة في ظل استمرار الحرب في أوكرانيا والتوترات الجيوسياسية
                الأخرى.
              </p>
            </div>

            <div className="news__item">
              <h2>
                الهند تتجاوز الصين لتصبح الدولة الأكثر سكانًا في العالم - 14
                يونيو 2023
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                alt="Flag of India"
                width="300"
              />
              <p>
                <span>
                  في 14 يونيو 2023، أعلنت الأمم المتحدة أن الهند تجاوزت الصين
                  لتصبح الدولة الأكثر سكانًا في العالم، حيث بلغ عدد سكانها 1.428
                  مليار نسمة.
                </span>
                يعتبر هذا التحول الديموغرافي حدثًا تاريخيًا، وله تداعيات كبيرة
                على الاقتصاد العالمي.
                <br />
                <br />
                <strong>التأثير المحتمل:</strong>
                <ul>
                  <li>
                    من المتوقع أن يؤدي النمو السكاني السريع في الهند إلى زيادة
                    الطلب على السلع والخدمات، مما قد يعزز النمو الاقتصادي في
                    البلاد.
                  </li>
                  <li>
                    في المقابل، تواجه الهند تحديات كبيرة في توفير فرص العمل
                    والخدمات الأساسية لسكانها المتزايدين.
                  </li>
                </ul>
                <br />
                هذا التحول الديموغرافي يثير أيضًا تساؤلات حول تأثيره على البيئة
                والموارد الطبيعية، وكيف ستتعامل الهند مع هذا النمو السكاني
                الهائل لتحقيق التنمية المستدامة.
              </p>
            </div>

            <div className="news__item">
              <h2>
                بنك الاحتياطي الفيدرالي يوقف رفع أسعار الفائدة مؤقتًا - 14 يونيو
                2023
              </h2>
              <img
                src="https://images.pexels.com/photos/259209/pexels-photo-259209.jpeg"
                alt="Federal Reserve building"
                width="300"
              />
              <p>
                <span>
                  في يونيو 2023، قرر مجلس الاحتياطي الفيدرالي الأمريكي الإبقاء
                  على أسعار الفائدة دون تغيير، منهيًا بذلك سلسلة من الزيادات
                  المتتالية في أسعار الفائدة التي استمرت لأكثر من عام.
                </span>
                كان هذا القرار بمثابة مفاجأة للبعض، حيث كان من المتوقع على نطاق
                واسع أن يرفع البنك المركزي أسعار الفائدة مرة أخرى لمكافحة
                التضخم.
                <br />
                <br />
                <strong>سبب الوقف المؤقت:</strong>
                <ul>
                  <li>
                    الرغبة في تقييم تأثير الزيادات السابقة في أسعار الفائدة على
                    الاقتصاد، ومراقبة البيانات الاقتصادية عن كثب قبل اتخاذ أي
                    قرارات أخرى.
                  </li>
                </ul>
                <br />
                <br />
                <strong>ماذا يعني هذا القرار؟</strong>
                <ul>
                  <li>
                    هذا يعني أن تكلفة الاقتراض ستظل مستقرة في الوقت الحالي، مما
                    قد يشجع على الاستثمار والإنفاق.
                  </li>
                  <li>
                    ومع ذلك، أشار الاحتياطي الفيدرالي إلى أنه قد يرفع أسعار
                    الفائدة مرة أخرى في المستقبل إذا لزم الأمر للسيطرة على
                    التضخم.
                  </li>
                </ul>
              </p>
            </div>

            <div className="news__item">
              <h2>
                {" "}
                ارتفاع أسعار النفط وسط توترات في الشرق الأوسط - 12 يوليو 2023
              </h2>
              <img
                src="https://cdn.pixabay.com/photo/2016/03/31/14/37/industry-1295301_960_720.png"
                alt="Oil rig at sunset"
                width="300"
              />
              <p>
                <span>
                  في يوليو 2023، شهدت أسواق النفط العالمية ارتفاعًا ملحوظًا في
                  الأسعار، مدفوعةً بتصاعد التوترات في منطقة الشرق الأوسط.
                </span>
                تسبب توقف جزئي في صادرات النفط من بعض الدول المنتجة الرئيسية،
                بالإضافة إلى المخاوف بشأن استقرار الإمدادات العالمية، في زيادة
                الطلب على النفط الخام.
                <br />
                <br />
                <strong>تأثير ارتفاع الأسعار:</strong>
                <ul>
                  <li>
                    ارتفاع تكلفة الوقود في العديد من البلدان، مما أثر على
                    المستهلكين والشركات على حد سواء.
                  </li>
                  <li>زيادة الضغوط التضخمية في الاقتصاد العالمي.</li>
                </ul>
                <br />
                على الرغم من الجهود المبذولة لتهدئة التوترات واستعادة الإنتاج،
                إلا أن أسعار النفط ظلت متقلبة، مما أثار مخاوف بشأن تأثير ذلك على
                الاقتصاد العالمي. وتؤكد هذه الأحداث على أهمية الشرق الأوسط
                كمنطقة حيوية لإنتاج النفط، وتأثير الأحداث الجيوسياسية في المنطقة
                على أسواق الطاقة العالمية.
              </p>
            </div>

            <div className="news__item">
              <h2>ميتا تطلق تطبيق ثريدز منافسًا لتويتر - 5 يوليو 2023</h2>
              <img
                src="https://images.pexels.com/photos/4050347/pexels-photo-4050347.jpeg"
                alt="Social media icons"
                width="300"
              />
              <p>
                <span>
                  في خطوة جريئة لتحدي هيمنة تويتر، أطلقت شركة ميتا (فيسبوك
                  سابقًا) تطبيقًا جديدًا للتواصل الاجتماعي باسم "ثريدز" في 5
                  يوليو 2023.
                </span>
                صُمم ثريدز ليكون منصة للمحادثات العامة في الوقت الفعلي، ويسمح
                للمستخدمين بنشر تحديثات نصية قصيرة والتفاعل معها من خلال
                الإعجابات والردود وإعادة النشر.
                <br />
                <br />
                <strong>النجاح الأولي:</strong>
                <ul>
                  <li>
                    حقق التطبيق نجاحًا كبيرًا في الأيام الأولى من إطلاقه، حيث
                    تجاوز عدد المستخدمين المسجلين 100 مليون في غضون أيام قليلة.
                  </li>
                </ul>
                <br />
                <strong>التحديات المستقبلية:</strong>
                <ul>
                  <li>
                    يواجه ثريدز تحديات كبيرة في الحفاظ على هذا الزخم وجذب
                    المستخدمين بعيدًا عن تويتر، الذي لا يزال يتمتع بقاعدة
                    مستخدمين كبيرة وولاء قوي.
                  </li>
                </ul>
                <br />
                يعتبر إطلاق ثريدز أحدث فصل في المنافسة الشرسة بين عمالقة
                التكنولوجيا على جذب انتباه المستخدمين وبياناتهم.
              </p>
            </div>

            <div className="news__item">
              <h2>
                أبل تكشف عن نظارة الواقع المختلط "Vision Pro" - 5 يونيو 2023
              </h2>
              <img
                src="https://www.apple.com/newsroom/images/product/vision/standard/Apple_vision-pro_hero_060523.jpg.large_2x.jpg"
                alt="Apple Vision Pro headset"
                width="300"
              />
              <p>
                <span>
                  في يونيو 2023، كشفت شركة أبل عن نظارة الواقع المختلط "Vision
                  Pro"، وهي أول منتج جديد رئيسي للشركة منذ سنوات.
                </span>
                تجمع النظارة بين الواقع الافتراضي والواقع المعزز، وتسمح
                للمستخدمين بالتفاعل مع المحتوى الرقمي بطرق جديدة ومبتكرة.
                <br />
                <br />
                <strong>ميزات النظارة:</strong>
                <ul>
                  <li>
                    تتيح للمستخدمين مشاهدة الأفلام والبرامج التلفزيونية والألعاب
                    في بيئة غامرة.
                  </li>
                  <li>
                    تسمح بالتفاعل مع التطبيقات والأدوات الرقمية في العالم
                    الحقيقي.
                  </li>
                  <li>تتميز بتصميم أنيق ومريح.</li>
                </ul>
                <br />
                <strong>التحديات:</strong>
                <ul>
                  <li>
                    على الرغم من أن النظارة لن تكون متاحة للشراء حتى عام 2024،
                    إلا أن الكشف عنها أثار حماسًا كبيرًا بين عشاق التكنولوجيا.
                  </li>
                  <li>
                    ومع ذلك، فإن السعر المرتفع للنظارة (3499 دولارًا) قد يحد من
                    انتشارها في البداية.
                  </li>
                </ul>
                <br />
                ويعتقد البعض أن Vision Pro يمكن أن تكون بداية لعصر جديد من
                الحوسبة المكانية، حيث تتفاعل الأجهزة الرقمية بسلاسة مع العالم
                الحقيقي.
              </p>
            </div>

            <div className="news__item">
              <h2>
                ارتفاع أسعار النفط بعد قرار أوبك+ بتخفيض الإنتاج - 4 يونيو 2023
              </h2>
              <img
                src="https://cdn.pixabay.com/photo/2016/03/31/14/37/industry-1295301_960_720.png"
                alt="Oil rig at sunset"
                width="300"
              />
              <p>
                <span>
                  في يونيو 2023، قررت منظمة الدول المصدرة للنفط وحلفاؤها (أوبك+)
                  تمديد تخفيضات إنتاج النفط حتى نهاية عام 2024.
                </span>
                وقد أدى هذا القرار إلى ارتفاع أسعار النفط الخام، حيث ارتفع سعر
                خام برنت القياسي إلى أكثر من 75 دولارًا للبرميل.
                <br />
                <br />
                <strong>الهدف من القرار:</strong>
                <ul>
                  <li>
                    تهدف أوبك+ من خلال هذا القرار إلى دعم أسعار النفط في ظل
                    المخاوف بشأن تباطؤ النمو الاقتصادي العالمي وتأثيره على الطلب
                    على النفط.
                  </li>
                </ul>
                <br />
                <strong>المخاوف:</strong>
                <ul>
                  <li>
                    أثار هذا القرار أيضًا مخاوف بشأن ارتفاع تكلفة الطاقة وتأثيره
                    على التضخم العالمي.
                  </li>
                </ul>
                <br />
                يُظهر هذا القرار مدى تأثير قرارات أوبك+ على أسواق الطاقة
                العالمية، وكيف يمكن أن تؤثر على الاقتصاد العالمي ككل.
              </p>
            </div>

            <div className="news__item">
              <h2>
                الهند تتجاوز الصين لتصبح الدولة الأكثر سكانًا في العالم - 14
                يونيو 2023
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                alt="Flag of India"
                width="300"
              />
              <p>
                <span>
                  في 14 يونيو 2023، أعلنت الأمم المتحدة أن الهند تجاوزت الصين
                  لتصبح الدولة الأكثر سكانًا في العالم، حيث بلغ عدد سكانها 1.428
                  مليار نسمة.
                </span>
                هذا التحول الديموغرافي يمثل حدثًا تاريخيًا، وله تداعيات كبيرة
                على الاقتصاد العالمي.
                <br />
                <br />
                <strong>التأثير المحتمل:</strong>
                <ul>
                  <li>
                    من المتوقع أن يؤدي النمو السكاني السريع في الهند إلى زيادة
                    الطلب على السلع والخدمات، مما قد يعزز النمو الاقتصادي في
                    البلاد ويجعلها سوقًا جاذبة للاستثمارات الأجنبية.
                  </li>
                  <li>
                    في المقابل، تواجه الهند تحديات كبيرة في توفير فرص العمل
                    والخدمات الأساسية لسكانها المتزايدين، مما يتطلب استثمارات
                    ضخمة في البنية التحتية والتعليم والصحة.
                  </li>
                </ul>
                <br />
                هذا التحول الديموغرافي يثير أيضًا تساؤلات حول تأثيره على البيئة
                والموارد الطبيعية، وكيف ستتعامل الهند مع هذا النمو السكاني
                الهائل لتحقيق التنمية المستدامة.
              </p>
            </div>

            <div className="news__item">
              <h2>
                بنك الاحتياطي الفيدرالي يوقف رفع أسعار الفائدة مؤقتًا - 14 يونيو
                2023
              </h2>
              <img
                src="https://images.pexels.com/photos/259209/pexels-photo-259209.jpeg"
                alt="Federal Reserve building"
                width="300"
              />
              <p>
                <span>
                  في يونيو 2023، قرر مجلس الاحتياطي الفيدرالي الأمريكي الإبقاء
                  على أسعار الفائدة دون تغيير، منهيًا بذلك سلسلة من الزيادات
                  المتتالية في أسعار الفائدة التي استمرت لأكثر من عام.
                </span>
                كان هذا القرار بمثابة مفاجأة للبعض، حيث كان من المتوقع على نطاق
                واسع أن يرفع البنك المركزي أسعار الفائدة مرة أخرى لمكافحة
                التضخم.
                <br />
                <br />
                <strong>سبب الوقف المؤقت:</strong>
                <ul>
                  <li>
                    الرغبة في تقييم تأثير الزيادات السابقة في أسعار الفائدة على
                    الاقتصاد، ومراقبة البيانات الاقتصادية عن كثب قبل اتخاذ أي
                    قرارات أخرى.
                  </li>
                </ul>
                <br />
                <br />
                <strong>ماذا يعني هذا القرار؟</strong>
                <ul>
                  <li>
                    هذا يعني أن تكلفة الاقتراض ستظل مستقرة في الوقت الحالي، مما
                    قد يشجع على الاستثمار والإنفاق.
                  </li>
                  <li>
                    ومع ذلك، أشار الاحتياطي الفيدرالي إلى أنه قد يرفع أسعار
                    الفائدة مرة أخرى في المستقبل إذا لزم الأمر للسيطرة على
                    التضخم.
                  </li>
                </ul>
              </p>
            </div>

            <div className="news__item">
              <h2>
                ارتفاع التضخم في الولايات المتحدة إلى أعلى مستوى له منذ 40 عامًا
                - 13 يوليو 2022
              </h2>
              <img
                src="https://images.pexels.com/photos/534296/pexels-photo-534296.jpeg"
                alt="US dollar bills"
                width="300"
              />
              <p>
                <span>
                  في يوليو 2022، شهدت الولايات المتحدة ارتفاعًا حادًا في معدلات
                  التضخم، حيث بلغ مؤشر أسعار المستهلك 9.1%، وهو أعلى مستوى له
                  منذ 40 عامًا.
                </span>
                هذا يعني أن الأسعار ارتفعت بشكل كبير في جميع المجالات، من الغذاء
                والوقود إلى الإسكان والرعاية الصحية، مما أثر على القدرة الشرائية
                للمواطنين.
                <br />
                <br />
                <strong>أسباب هذا الارتفاع:</strong>
                <ul>
                  <li>
                    اضطرابات سلسلة التوريد العالمية الناجمة عن جائحة كورونا.
                  </li>
                  <li>الحرب في أوكرانيا التي أدت إلى ارتفاع أسعار الطاقة.</li>
                  <li>
                    السياسات النقدية والمالية التيسيرية التي اتخذتها الحكومة
                    الأمريكية لمواجهة الركود الاقتصادي.
                  </li>
                </ul>
                <br />
                دفع ارتفاع التضخم الاحتياطي الفيدرالي الأمريكي إلى رفع أسعار
                الفائدة بشكل حاد في محاولة لكبح جماح التضخم. وقد أدت هذه
                الزيادات في أسعار الفائدة إلى ارتفاع تكلفة الاقتراض، مما أثر على
                سوق الإسكان والاستثمار، وأثار مخاوف بشأن احتمالية حدوث ركود
                اقتصادي.
              </p>
            </div>

            <div className="news__item">
              <h2>انهيار بنك "First Republic" الأمريكي - 1 مايو 2023</h2>
              <img
                src="https://www.gannett-cdn.com/-mm-/4e0291d0a7d23933909c956939c809f908930d08/c=0-97-2000-1213/local/-/media/2018/04/25/USATODAY/USATODAY/636615684793578675-firstrepublic.jpg?width=660&height=373&fit=crop&format=pjpg&auto=webp"
                alt="First Republic Bank building"
                width="300"
              />
              <p>
                <span>
                  في مايو 2023، شهد القطاع المصرفي الأمريكي ضربة موجعة أخرى
                  بانهيار بنك "First Republic"، وهو بنك إقليمي كبير يقع في سان
                  فرانسيسكو.
                </span>
                جاء هذا الانهيار بعد أشهر قليلة من انهيار بنك سيليكون فالي، مما
                زاد من المخاوف بشأن استقرار النظام المصرفي الأمريكي.
                <br />
                <br />
                <strong>أسباب الانهيار:</strong>
                <ul>
                  <li>
                    ارتفاع أسعار الفائدة تسبب في خسائر كبيرة للبنك في محفظة
                    سنداته.
                  </li>
                  <li>
                    أدى ذلك إلى هروب جماعي للمودعين وسحب أكثر من 100 مليار دولار
                    من الودائع في الربع الأول من العام.
                  </li>
                </ul>
                <br />
                على الرغم من محاولات الإنقاذ من قبل الحكومة الأمريكية والبنوك
                الأخرى، إلا أن First Republic لم يتمكن من الصمود، وأعلنت المؤسسة
                الفيدرالية للتأمين على الودائع (FDIC) عن إغلاق البنك وبيعه إلى
                JPMorgan Chase. كان هذا ثالث أكبر فشل مصرفي في تاريخ الولايات
                المتحدة، وأثار تساؤلات حول الرقابة التنظيمية على البنوك
                والإجراءات اللازمة لمنع تكرار مثل هذه الأزمات.
              </p>
            </div>

            <div className="news__item">
              <h2>
                إقرار الكونجرس الأمريكي مشروع قانون سقف الدين العام - 3 يونيو
                2023
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/US_Capitol_at_night_edit1.jpg/1200px-US_Capitol_at_night_edit1.jpg"
                alt="US Capitol building"
                width="300"
              />
              <p>
                <span>
                  في خطوةٍ حاسمة لتجنب كارثة اقتصادية، أقر الكونجرس الأمريكي
                  مشروع قانون لتعليق سقف الدين العام حتى يناير 2025.
                </span>
                جاء هذا الإقرار بعد مفاوضات طويلة وشاقة بين البيت الأبيض
                والجمهوريين في مجلس النواب، الذين كانوا يطالبون بتخفيضات كبيرة
                في الإنفاق الحكومي مقابل رفع سقف الدين.
                <br />
                <br />
                <strong>أهمية هذا القرار:</strong>
                <ul>
                  <li>
                    أدى هذا الإقرار إلى تجنب تخلف الولايات المتحدة عن سداد
                    ديونها، وهو ما كان من شأنه أن يكون له عواقب وخيمة على
                    الاقتصاد العالمي.
                  </li>
                </ul>
                <br />
                <strong>المخاوف:</strong>
                <ul>
                  <li>
                    أثار مشروع القانون أيضًا مخاوف بشأن تأثيره على الإنفاق على
                    البرامج الاجتماعية والاقتصادية في المستقبل، حيث قد يؤدي إلى
                    تقليص بعض الخدمات الحكومية.
                  </li>
                </ul>
                <br />
                يعتبر هذا القرار انتصارًا للرئيس بايدن الذي سعى إلى تجنب أزمة
                الديون، ولكنه أيضًا يسلط الضوء على الانقسامات السياسية العميقة
                في الولايات المتحدة حول قضايا الإنفاق الحكومي والضرائب.
              </p>
            </div>

            <div className="news__item">
              <h2>
                تراجع أسعار النفط بعد قرار أوبك+ بتخفيض الإنتاج بشكل مفاجئ - 2
                أبريل 2023
              </h2>
              <img
                src="https://cdn.pixabay.com/photo/2016/03/31/14/37/industry-1295301_960_720.png"
                alt="Oil rig at sunset"
                width="300"
              />
              <p>
                <span>
                  في خطوة مفاجئة هزت أسواق الطاقة العالمية، أعلنت منظمة الدول
                  المصدرة للنفط وحلفاؤها (أوبك+) في 2 أبريل 2023 عن قرار بتخفيض
                  إنتاج النفط بأكثر من مليون برميل يوميًا اعتبارًا من مايو.
                </span>
                وقد أدى هذا القرار إلى ارتفاع أسعار النفط الخام بشكل حاد، حيث
                قفز سعر خام برنت القياسي بأكثر من 6% ليصل إلى 85 دولارًا
                للبرميل.
                <br />
                <br />
                <strong>مبررات أوبك+:</strong>
                <ul>
                  <li>
                    بررت أوبك+ هذا القرار بضرورة دعم استقرار السوق في ظل المخاوف
                    بشأن تباطؤ النمو الاقتصادي العالمي.
                  </li>
                </ul>
                <br />
                <strong>الانتقادات:</strong>
                <ul>
                  <li>
                    أثار هذا القرار انتقادات من بعض الدول المستهلكة للنفط، التي
                    اعتبرت أنه سيؤدي إلى ارتفاع تكلفة الطاقة وزيادة الضغوط
                    التضخمية.
                  </li>
                </ul>
                <br />
                يظهر هذا القرار كيف يمكن لقرارات أوبك+ أن تؤثر بشكل كبير على
                أسعار النفط العالمية، وبالتالي على الاقتصاد العالمي ككل.
              </p>
            </div>

            <div className="news__item">
              <h2>
                الاحتياطي الفيدرالي الأمريكي يرفع أسعار الفائدة للمرة العاشرة
                على التوالي - 3 مايو 2023
              </h2>
              <img
                src="https://images.pexels.com/photos/374870/pexels-photo-374870.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Federal Reserve building"
                width="300"
              />
              <p>
                <span>
                  في مايو 2023، واصل مجلس الاحتياطي الفيدرالي الأمريكي حملته
                  لمكافحة التضخم من خلال رفع أسعار الفائدة للمرة العاشرة على
                  التوالي.
                </span>
                رفع البنك المركزي سعر الفائدة القياسي بمقدار ربع نقطة مئوية،
                ليصل إلى نطاق يتراوح بين 5% و 5.25%.
                <br />
                <br />
                <strong>الهدف من رفع الفائدة:</strong>
                <ul>
                  <li>
                    كان الهدف من هذه الزيادات المتتالية في أسعار الفائدة هو كبح
                    جماح التضخم الذي وصل إلى أعلى مستوياته في أربعة عقود.
                  </li>
                </ul>
                <br />
                <strong>التحديات:</strong>
                <ul>
                  <li>
                    على الرغم من أن التضخم بدأ يظهر بعض علامات التراجع، إلا أن
                    الاحتياطي الفيدرالي أكد التزامه بمواصلة رفع أسعار الفائدة
                    حتى يعود التضخم إلى هدفه البالغ 2%.
                  </li>
                  <li>
                    وقد أثارت هذه السياسة النقدية المتشددة مخاوف بشأن تأثيرها
                    على النمو الاقتصادي، خاصة مع تزايد المؤشرات على تباطؤ
                    الاقتصاد العالمي.
                  </li>
                </ul>
              </p>
            </div>

            <div className="news__item">
              <h2>
                تراجع أسعار الذهب بعد بيانات اقتصادية قوية من الولايات المتحدة -
                6 أبريل 2023
              </h2>
              <img
                src="https://images.pexels.com/photos/4132438/pexels-photo-4132438.jpeg"
                alt="Gold bars stacked"
                width="300"
              />
              <p>
                <span>
                  في أبريل 2023، شهدت أسعار الذهب تراجعًا ملحوظًا بعد صدور
                  بيانات اقتصادية قوية من الولايات المتحدة.
                </span>
                أظهرت البيانات نموًا قويًا في سوق العمل وانخفاضًا في طلبات إعانة
                البطالة، مما عزز التوقعات بأن الاحتياطي الفيدرالي الأمريكي
                سيواصل رفع أسعار الفائدة.
                <br />
                <br />
                <strong>تأثير البيانات الاقتصادية:</strong>
                <ul>
                  <li>
                    أدت هذه البيانات إلى زيادة التوقعات بأن الاحتياطي الفيدرالي
                    الأمريكي سيواصل رفع أسعار الفائدة، مما قلل من جاذبية الذهب
                    كملاذ آمن.
                  </li>
                  <li>
                    وانخفض سعر الأونصة إلى أقل من 1950 دولارًا، بعد أن كان قد
                    وصل إلى أعلى مستوى له في عام في وقت سابق من الشهر.
                  </li>
                </ul>
                <br />
                على الرغم من هذا التراجع، لا يزال الذهب يعتبر استثمارًا جذابًا
                على المدى الطويل، خاصة في ظل المخاوف بشأن التضخم وعدم الاستقرار
                الجيوسياسي.
              </p>
            </div>

            <div className="news__item">
              <h2>تويتر يزيل علامة التحقق الزرقاء القديمة - 20 أبريل 2023</h2>
              <img
                src="https://images.pexels.com/photos/4553617/pexels-photo-4553617.jpeg"
                alt="Twitter logo on a phone screen"
                width="300"
              />
              <p>
                <span>
                  في خطوة أثارت جدلاً واسعًا، أزال تويتر في 20 أبريل 2023 علامة
                  التحقق الزرقاء القديمة من الحسابات التي لم تشترك في خدمة تويتر
                  بلو المدفوعة.
                </span>
                كانت هذه العلامة تستخدم سابقًا للتحقق من هوية الشخصيات العامة
                والمشاهير والعلامات التجارية، ولكن بعد استحواذ إيلون ماسك على
                الشركة، قرر جعلها ميزة متاحة فقط للمشتركين في تويتر بلو.
                <br />
                <br />
                <strong>الجدل حول القرار:</strong>
                <ul>
                  <li>
                    اعتبره البعض بمثابة إزالة لميزة مهمة للتحقق من الهوية، مما
                    يزيد من صعوبة التمييز بين الحسابات الحقيقية والمزيفة.
                  </li>
                  <li>
                    في حين رأى آخرون أنه خطوة ضرورية لجعل تويتر أكثر استدامة
                    ماليًا، وتوفير مصدر دخل إضافي للشركة.
                  </li>
                </ul>
                <br />
                أدى هذا القرار إلى ظهور العديد من الحسابات المزيفة التي انتحلت
                شخصيات عامة، مما زاد من المخاوف بشأن انتشار المعلومات المضللة
                على المنصة. ويظل الجدل مستمرًا حول تأثير هذا القرار على مصداقية
                تويتر ودوره في النقاش العام.
              </p>
            </div>

            <div className="news__item">
              <h2>
                صندوق النقد الدولي يحذر من تباطؤ النمو الاقتصادي العالمي - 11
                أبريل 2023
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/International_Monetary_Fund_logo.svg/1200px-International_Monetary_Fund_logo.svg.png"
                alt="International Monetary Fund logo"
                width="300"
              />
              <p>
                <span>
                  في أبريل 2023، أصدر صندوق النقد الدولي تقريره الدوري حول آفاق
                  الاقتصاد العالمي، محذرًا من تباطؤ النمو الاقتصادي العالمي في
                  عام 2023.
                </span>
                يتوقع الصندوق أن ينمو الاقتصاد العالمي بنسبة 2.8% فقط، بانخفاض
                عن 3.4% في عام 2022.
                <br />
                <br />
                <strong>أسباب التباطؤ:</strong>
                <ul>
                  <li>
                    استمرار الحرب في أوكرانيا، وما نتج عنها من اضطرابات في سلاسل
                    الإمداد وارتفاع أسعار الطاقة والغذاء.
                  </li>
                  <li>
                    ارتفاع أسعار الفائدة في العديد من البلدان، في محاولة لكبح
                    جماح التضخم، مما يزيد من تكلفة الاقتراض ويؤثر على الاستثمار
                    والإنفاق.
                  </li>
                  <li>
                    تشديد الأوضاع المالية العالمية، مما يجعل من الصعب على الدول
                    النامية الحصول على التمويل اللازم للنمو.
                  </li>
                </ul>
                <br />
                حث صندوق النقد الدولي الحكومات على اتخاذ إجراءات لدعم النمو
                الاقتصادي، بما في ذلك زيادة الإنفاق على البنية التحتية
                والاستثمار في التعليم والتدريب. كما شدد على أهمية التعاون الدولي
                لمعالجة التحديات الاقتصادية العالمية.
              </p>
            </div>

            <div className="news__item">
              <h2>انهيار بنك "First Republic" الأمريكي - 1 مايو 2023</h2>
              <img
                src="https://www.gannett-cdn.com/-mm-/4e0291d0a7d23933909c956939c809f908930d08/c=0-97-2000-1213/local/-/media/2018/04/25/USATODAY/USATODAY/636615684793578675-firstrepublic.jpg?width=660&height=373&fit=crop&format=pjpg&auto=webp"
                alt="First Republic Bank building"
                width="300"
              />
              <p>
                <span>
                  في مطلع شهر مايو 2023، اهتز القطاع المصرفي الأمريكي مرة أخرى
                  بانهيار بنك "First Republic"، وهو بنك إقليمي كبير يقع في سان
                  فرانسيسكو.
                </span>
                جاء هذا الانهيار بعد أشهر قليلة من انهيار بنك سيليكون فالي، مما
                زاد من المخاوف بشأن استقرار النظام المصرفي الأمريكي.
                <br />
                <br />
                <strong>أسباب الانهيار:</strong>
                <ul>
                  <li>
                    ارتفاع أسعار الفائدة تسبب في خسائر كبيرة للبنك في محفظة
                    سنداته.
                  </li>
                  <li>
                    أدى ذلك إلى هروب جماعي للمودعين وسحب أكثر من 100 مليار دولار
                    من الودائع في الربع الأول من العام.
                  </li>
                </ul>
                <br />
                على الرغم من محاولات الإنقاذ من قبل الحكومة الأمريكية والبنوك
                الأخرى، إلا أن First Republic لم يتمكن من الصمود، وأعلنت المؤسسة
                الفيدرالية للتأمين على الودائع (FDIC) عن إغلاق البنك وبيعه إلى
                JPMorgan Chase. كان هذا ثالث أكبر فشل مصرفي في تاريخ الولايات
                المتحدة، وأثار تساؤلات حول الرقابة التنظيمية على البنوك
                والإجراءات اللازمة لمنع تكرار مثل هذه الأزمات.
              </p>
            </div>

            <div className="news__item">
              <h2>
                الاحتياطي الفيدرالي الأمريكي يرفع أسعار الفائدة للمرة العاشرة
                على التوالي - 3 مايو 2023
              </h2>
              <img
                src="https://images.pexels.com/photos/374870/pexels-photo-374870.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Federal Reserve building"
                width="300"
              />
              <p>
                <span>
                  في مايو 2023، واصل مجلس الاحتياطي الفيدرالي الأمريكي حملته
                  لمكافحة التضخم من خلال رفع أسعار الفائدة للمرة العاشرة على
                  التوالي.
                </span>
                رفع البنك المركزي سعر الفائدة القياسي بمقدار ربع نقطة مئوية،
                ليصل إلى نطاق يتراوح بين 5% و 5.25%.
                <br />
                <br />
                <strong>الهدف من رفع الفائدة:</strong>
                <ul>
                  <li>
                    كان الهدف من هذه الزيادات المتتالية في أسعار الفائدة هو كبح
                    جماح التضخم الذي وصل إلى أعلى مستوياته في أربعة عقود.
                  </li>
                </ul>
                <br />
                <strong>التحديات:</strong>
                <ul>
                  <li>
                    على الرغم من أن التضخم بدأ يظهر بعض علامات التراجع، إلا أن
                    الاحتياطي الفيدرالي أكد التزامه بمواصلة رفع أسعار الفائدة
                    حتى يعود التضخم إلى هدفه البالغ 2%.
                  </li>
                  <li>
                    وقد أثارت هذه السياسة النقدية المتشددة مخاوف بشأن تأثيرها
                    على النمو الاقتصادي، خاصة مع تزايد المؤشرات على تباطؤ
                    الاقتصاد العالمي.
                  </li>
                </ul>
              </p>
            </div>

            <div className="news__item">
              <h2>
                تراجع أسعار النفط بعد قرار أوبك+ بتخفيض الإنتاج بشكل مفاجئ - 2
                أبريل 2023
              </h2>
              <img
                src="https://cdn.pixabay.com/photo/2016/03/31/14/37/industry-1295301_960_720.png"
                alt="Oil rig at sunset"
                width="300"
              />
              <p>
                <span>
                  في خطوة مفاجئة هزت أسواق الطاقة العالمية، أعلنت منظمة الدول
                  المصدرة للنفط وحلفاؤها (أوبك+) في 2 أبريل 2023 عن قرار بتخفيض
                  إنتاج النفط بأكثر من مليون برميل يوميًا اعتبارًا من مايو.
                </span>
                وقد أدى هذا القرار إلى ارتفاع أسعار النفط الخام بشكل حاد، حيث
                قفز سعر خام برنت القياسي بأكثر من 6% ليصل إلى 85 دولارًا
                للبرميل.
                <br />
                <br />
                <strong>مبررات أوبك+:</strong>
                <ul>
                  <li>
                    بررت أوبك+ هذا القرار بضرورة دعم استقرار السوق في ظل المخاوف
                    بشأن تباطؤ النمو الاقتصادي العالمي.
                  </li>
                </ul>
                <br />
                <strong>الانتقادات:</strong>
                <ul>
                  <li>
                    أثار هذا القرار انتقادات من بعض الدول المستهلكة للنفط، التي
                    اعتبرت أنه سيؤدي إلى ارتفاع تكلفة الطاقة وزيادة الضغوط
                    التضخمية.
                  </li>
                </ul>
                <br />
                يظهر هذا القرار كيف يمكن لقرارات أوبك+ أن تؤثر بشكل كبير على
                أسعار النفط العالمية، وبالتالي على الاقتصاد العالمي ككل.
              </p>
            </div>

            <div className="news__item">
              <h2>فيسبوك تغير اسمها إلى ميتا - 28 أكتوبر 2021</h2>
              <img
                src="https://images.unsplash.com/photo-1555024741-7470fd6ae4d4"
                alt="Meta logo"
                width="300"
              />
              <p>
                <span>
                  في أكتوبر 2021، أعلنت شركة فيسبوك عن تغيير اسمها إلى ميتا، في
                  خطوة تعكس طموحها للتحول إلى شركة ميتافيرس.
                </span>
                الميتافيرس هو عالم افتراضي حيث يمكن للناس التفاعل والعمل واللعب
                باستخدام تقنيات الواقع الافتراضي والمعزز. يعتقد مارك زوكربيرج،
                الرئيس التنفيذي لشركة ميتا، أن الميتافيرس هو مستقبل الإنترنت،
                وأن شركته ستلعب دورًا رائدًا في تطويره.
                <br />
                <br />
                <strong>الاستثمارات والطموحات:</strong>
                <ul>
                  <li>
                    وقد استثمرت ميتا مليارات الدولارات في تقنيات الواقع
                    الافتراضي والمعزز، وتعمل على بناء منصة ميتافيرس خاصة بها.
                  </li>
                </ul>
                <br />
                <strong>التحديات:</strong>
                <ul>
                  <li>
                    لا يزال الميتافيرس في مراحله الأولى، وهناك العديد من
                    التحديات التي يجب التغلب عليها قبل أن يصبح حقيقة واقعة.
                  </li>
                </ul>
                <br />
                على الرغم من ذلك، فإن تغيير اسم فيسبوك إلى ميتا يمثل لحظة
                تاريخية في تطور الشركة وقطاع التكنولوجيا بشكل عام، ويعكس رؤية
                الشركة لمستقبل الإنترنت.
              </p>
            </div>

            <div className="news__item">
              <h2>
                إيلون ماسك يشتري تويتر مقابل 44 مليار دولار - 27 أكتوبر 2022
              </h2>
              <img
                src="https://images.unsplash.com/photo-1601285518320-80e79041c31d"
                alt="Twitter logo on a building"
                width="300"
              />
              <p>
                <span>
                  في أكتوبر 2022، أتم إيلون ماسك، الملياردير المثير للجدل ورئيس
                  شركة تسلا، صفقة استحواذه على تويتر مقابل 44 مليار دولار،
                  منهياً بذلك شهوراً من المفاوضات الدرامية والتقاضي.
                </span>
                دخل ماسك إلى مقر تويتر حاملاً حوضاً، في إشارة إلى عبارة "let
                that sink in"، معلناً بداية عهد جديد لمنصة التواصل الاجتماعي.
                <br />
                <br />
                <strong>التغييرات الجذرية:</strong>
                <ul>
                  <li>
                    منذ ذلك الحين، أجرى ماسك تغييرات جذرية، شملت تسريح جماعي
                    للموظفين، وإعادة هيكلة الشركة، وإطلاق خدمة تويتر بلو
                    المدفوعة.
                  </li>
                  <li>
                    كما أعاد ماسك حسابات شخصيات مثيرة للجدل كانت قد حظرت سابقًا،
                    مما أثار مخاوف بشأن حرية التعبير وحدودها.
                  </li>
                </ul>
                <br />
                <strong>الجدل والتكهنات:</strong>
                <ul>
                  <li>
                    يرى البعض أن ماسك يحاول تحويل تويتر إلى منصة أكثر انفتاحاً،
                    بينما يخشى آخرون من أن تغييرات ماسك قد تؤدي إلى انتشار
                    المعلومات المضللة وخطاب الكراهية.
                  </li>
                </ul>
                <br />
                يبقى أن نرى كيف ستتطور تويتر تحت قيادة ماسك، ولكن من المؤكد أن
                هذه الصفقة ستظل واحدة من أهم الأحداث في تاريخ التكنولوجيا.
              </p>
            </div>

            <div className="news__item">
              <h2>
                حرب روسيا على أوكرانيا تؤدي إلى ارتفاع أسعار الطاقة والغذاء - 24
                فبراير 2022
              </h2>
              <img
                src="https://images.pexels.com/photos/6994660/pexels-photo-6994660.jpeg"
                alt="Wheat field in Ukraine"
                width="300"
              />
              <p>
                <span>
                  في فبراير 2022، شنت روسيا غزوًا واسع النطاق على أوكرانيا، مما
                  أدى إلى اندلاع حرب مدمرة لا تزال مستمرة حتى اليوم.
                </span>
                كان لهذا الصراع تأثير كبير على الاقتصاد العالمي، حيث أدى إلى
                ارتفاع أسعار الطاقة والغذاء بشكل حاد.
                <br />
                <br />
                <strong>تأثير الحرب على الاقتصاد العالمي:</strong>
                <ul>
                  <li>
                    تعتبر روسيا وأوكرانيا من أكبر مصدري القمح والنفط والغاز
                    الطبيعي في العالم، وتسبب الصراع في تعطيل سلاسل التوريد
                    العالمية لهذه السلع الأساسية.
                  </li>
                  <li>
                    وقد أدى ارتفاع أسعار الطاقة والغذاء إلى تفاقم التضخم في
                    العديد من البلدان، مما زاد الضغط على الأسر والشركات.
                  </li>
                  <li>
                    كما أدت الحرب إلى فرض عقوبات اقتصادية صارمة على روسيا من قبل
                    الولايات المتحدة والاتحاد الأوروبي وحلفائهما، مما أدى إلى
                    مزيد من عدم الاستقرار في الاقتصاد العالمي.
                  </li>
                </ul>
                <br />
                بالإضافة إلى التداعيات الاقتصادية، تسببت الحرب أيضًا في أزمة
                إنسانية كبيرة، حيث نزح الملايين من الأوكرانيين من ديارهم. وقد
                أدى الصراع إلى زيادة المخاوف بشأن الأمن الغذائي والطاقة في
                العالم، وألقى بظلاله على آفاق النمو الاقتصادي العالمي.
              </p>
            </div>

            <div className="news__item">
              <h2>الصين تفرض قيودًا صارمة على قطاع التكنولوجيا - 2020-2021</h2>
              <img
                src="https://images.unsplash.com/photo-1526379095862-d447e5f36c6a"
                alt="Computer code on a screen"
                width="300"
              />
              <p>
                <span>
                  في سلسلة من الإجراءات التنظيمية الصارمة، فرضت الحكومة الصينية
                  قيودًا واسعة النطاق على قطاع التكنولوجيا في البلاد خلال عامي
                  2020 و 2021.
                </span>
                شملت هذه الإجراءات فرض غرامات ضخمة على شركات التكنولوجيا
                العملاقة مثل علي بابا وتينسنت، وتشديد الرقابة على عمليات
                الاكتتاب العام الأولي، وفرض قواعد جديدة بشأن حماية البيانات
                ومكافحة الاحتكار.
                <br />
                <br />
                <strong>أسباب هذه القيود:</strong>
                <ul>
                  <li>
                    رغبة الحكومة الصينية في كبح جماح قوة شركات التكنولوجيا
                    العملاقة وتأثيرها المتزايد على الاقتصاد والمجتمع.
                  </li>
                  <li>
                    الحفاظ على الاستقرار الاجتماعي والسياسي، وضمان عدم استخدام
                    التكنولوجيا لتحدي سلطة الحزب الشيوعي.
                  </li>
                  <li>حماية المستهلكين وضمان المنافسة العادلة في السوق.</li>
                </ul>
                <br />
                <strong>التأثير على القطاع:</strong>
                <ul>
                  <li>
                    أدت هذه الإجراءات إلى انخفاض حاد في قيمة أسهم شركات
                    التكنولوجيا الصينية، وأثارت مخاوف بشأن تأثيرها على الابتكار
                    ونمو القطاع.
                  </li>
                </ul>
                <br />
                لا يزال الجدل قائمًا حول مدى تأثير هذه القيود على المدى الطويل،
                فبينما يرى البعض أنها ضرورية لضمان التنمية المستدامة والمسؤولة
                لقطاع التكنولوجيا في الصين، يعتقد آخرون أنها تعيق النمو
                الاقتصادي وتضر بسمعة الصين كوجهة للاستثمار.
              </p>
            </div>

            <div className="news__item">
              <h2>
                خروج بريطانيا من الاتحاد الأوروبي (بريكست) - 31 يناير 2020
              </h2>
              <img
                src="https://images.unsplash.com/photo-1542346625-f9f9b78f3d96"
                alt="British flag and European Union flag"
                width="300"
              />
              <p>
                <span>
                  في 31 يناير 2020، غادرت المملكة المتحدة الاتحاد الأوروبي
                  رسميًا بعد سنوات من المفاوضات والجدل السياسي، منهية بذلك
                  عضويتها التي استمرت 47 عامًا.
                </span>
                يُعرف هذا الحدث باسم "بريكست"، وقد أثار انقسامًا عميقًا في
                المجتمع البريطاني، حيث كان مؤيدو بريكست يعتقدون أن مغادرة
                الاتحاد الأوروبي ستسمح للمملكة المتحدة باستعادة السيطرة على
                حدودها وقوانينها، في حين أن المعارضين كانوا يخشون من أن ذلك سيضر
                بالاقتصاد ويعزل البلاد.
                <br />
                <br />
                <strong>ما بعد بريكست:</strong>
                <ul>
                  <li>
                    بعد خروج بريطانيا من الاتحاد الأوروبي، دخلت فترة انتقالية
                    استمرت حتى نهاية عام 2020، وخلال هذه الفترة، ظلت المملكة
                    المتحدة جزءًا من السوق الموحدة للاتحاد الأوروبي والاتحاد
                    الجمركي.
                  </li>
                  <li>
                    في نهاية الفترة الانتقالية، دخلت المملكة المتحدة والاتحاد
                    الأوروبي في اتفاقية تجارة وحريات جديدة تنظم علاقتهما
                    المستقبلية.
                  </li>
                </ul>
                <br />
                لا يزال تأثير بريكست على الاقتصاد البريطاني محل نقاش، ولكن من
                الواضح أنه كان حدثًا تاريخيًا كان له تداعيات كبيرة على المملكة
                المتحدة والاتحاد الأوروبي والعالم. وقد أدى بريكست إلى تغييرات
                كبيرة في التجارة والهجرة والتعاون بين المملكة المتحدة والاتحاد
                الأوروبي، ولا تزال آثاره تتكشف حتى اليوم.
              </p>
            </div>

            <div className="news__item">
              <h2>
                جائحة كورونا تتسبب في أكبر ركود اقتصادي عالمي منذ الكساد الكبير
                - 2020
              </h2>
              <img
                src="https://images.unsplash.com/photo-1584735175015-d87348c5002e"
                alt="Empty city street during lockdown"
                width="300"
              />
              <p>
                <span>
                  في عام 2020، تسببت جائحة كورونا في أكبر ركود اقتصادي عالمي منذ
                  الكساد الكبير في ثلاثينيات القرن الماضي.
                </span>
                أدت عمليات الإغلاق الواسعة النطاق وتدابير التباعد الاجتماعي إلى
                توقف النشاط الاقتصادي في جميع أنحاء العالم، مما أدى إلى انخفاض
                حاد في الناتج المحلي الإجمالي وارتفاع معدلات البطالة.
                <br />
                <br />
                <strong>التداعيات الاقتصادية:</strong>
                <ul>
                  <li>
                    وفقًا لصندوق النقد الدولي، انكمش الاقتصاد العالمي بنسبة 3.3%
                    في عام 2020، وهو أسوأ أداء له منذ الكساد الكبير.
                  </li>
                  <li>
                    تسببت الجائحة في خسائر فادحة في الوظائف، وأدت إلى زيادة في
                    معدلات الفقر والجوع في جميع أنحاء العالم.
                  </li>
                </ul>
                <br />
                على الرغم من أن العديد من البلدان شهدت انتعاشًا اقتصاديًا في عام
                2021، إلا أن تداعيات الجائحة لا تزال محسوسة حتى اليوم، حيث تواجه
                الاقتصادات العالمية تحديات مثل التضخم واضطرابات سلسلة التوريد.
                كما أدت الجائحة إلى تسريع بعض الاتجاهات الاقتصادية، مثل التحول
                إلى العمل عن بعد وزيادة الاعتماد على التجارة الإلكترونية.
              </p>
            </div>

            <div className="news__item">
              <h2>
                الاحتياطي الفيدرالي الأمريكي يخفض أسعار الفائدة إلى الصفر
                تقريبًا - 15 مارس 2020
              </h2>
              <img
                src="https://images.pexels.com/photos/259209/pexels-photo-259209.jpeg"
                alt="Federal Reserve building"
                width="300"
              />
              <p>
                <span>
                  في خطوة دراماتيكية لمواجهة التداعيات الاقتصادية لجائحة كورونا،
                  قام مجلس الاحتياطي الفيدرالي الأمريكي في مارس 2020 بخفض أسعار
                  الفائدة إلى نطاق يتراوح بين 0% و0.25%، وهو أدنى مستوى لها على
                  الإطلاق.
                </span>
                كان هذا الخفض جزءًا من حزمة إجراءات غير مسبوقة اتخذها البنك
                المركزي لدعم الاقتصاد الأمريكي، بما في ذلك شراء سندات الخزانة
                والأوراق المالية المدعومة بالرهن العقاري بكميات كبيرة.
                <br />
                <br />
                <strong>الهدف من هذا القرار:</strong>
                <ul>
                  <li>
                    تشجيع الاقتراض والاستثمار والإنفاق، وبالتالي تحفيز النمو
                    الاقتصادي.
                  </li>
                  <li>تخفيف الضغط على الشركات والأسر المتضررة من الجائحة.</li>
                </ul>
                <br />
                <strong>المخاوف:</strong>
                <ul>
                  <li>
                    أثارت أسعار الفائدة المنخفضة للغاية أيضًا مخاوف بشأن التضخم
                    وتشكل فقاعات في أسواق الأصول.
                  </li>
                </ul>
                <br />
                وقد استمرت هذه السياسة النقدية التيسيرية لعدة سنوات، حتى بدأ
                الاحتياطي الفيدرالي في رفع أسعار الفائدة في عام 2022 لمكافحة
                التضخم المتزايد. وقد أثار هذا التحول في السياسة النقدية مخاوف
                بشأن تأثيره على النمو الاقتصادي، خاصة في ظل التحديات التي
                يواجهها الاقتصاد العالمي.
              </p>
            </div>

            <div className="news__item">
              <h2>
                شركة أمازون تشتري متاجر "Whole Foods Market" - 28 أغسطس 2017
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
                alt="Amazon logo"
                width="300"
              />
              <p>
                <span>
                  في أغسطس 2017، أعلنت شركة أمازون، عملاق التجارة الإلكترونية،
                  عن استحواذها على سلسلة متاجر "Whole Foods Market" الأمريكية
                  المتخصصة في بيع الأغذية العضوية والطبيعية، في صفقة بلغت قيمتها
                  13.7 مليار دولار.
                </span>
                كانت هذه الصفقة الأكبر في تاريخ أمازون آنذاك، وشكلت نقطة تحول في
                استراتيجية الشركة للتوسع في قطاع تجارة التجزئة التقليدية.
                <br />
                <br />
                <strong>أهمية الصفقة:</strong>
                <ul>
                  <li>
                    أتاحت لأمازون الوصول إلى شبكة واسعة من المتاجر الفعلية، مما
                    عزز حضورها في قطاع البقالة.
                  </li>
                  <li>
                    مكنت أمازون من دمج خبراتها في التجارة الإلكترونية والخدمات
                    اللوجستية مع خبرة Whole Foods في تجارة التجزئة التقليدية،
                    مما أدى إلى ابتكارات جديدة في مجال التسوق وتوصيل البقالة.
                  </li>
                  <li>
                    أثارت الصفقة مخاوف بشأن المنافسة في قطاع البقالة، حيث يخشى
                    البعض من أن هيمنة أمازون قد تؤدي إلى إضعاف المنافسين ورفع
                    الأسعار.
                  </li>
                </ul>
              </p>
            </div>

            <div className="news__item">
              <h2>
                الصين تطلق أول قمر صناعي للاتصالات الكمومية - 16 أغسطس 2016
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Micius_satellite.jpg/1200px-Micius_satellite.jpg"
                alt="Micius satellite"
                width="300"
              />
              <p>
                <span>
                  في أغسطس 2016، أطلقت الصين أول قمر صناعي للاتصالات الكمومية في
                  العالم، وهو "ميكيوس".
                </span>
                يعتبر هذا الإنجاز خطوة كبيرة في مجال تطوير تكنولوجيا الاتصالات
                الكمومية، التي تعد أكثر أمانًا وسرعة من تقنيات الاتصالات
                التقليدية.
                <br />
                <br />
                <strong>أهمية هذا الإنجاز:</strong>
                <ul>
                  <li>
                    يمهد الطريق لتطوير شبكات اتصالات كمومية عالمية، والتي يمكن
                    استخدامها في مجموعة متنوعة من التطبيقات، بما في ذلك الخدمات
                    المصرفية عبر الإنترنت، والاتصالات الحكومية، والبحث العلمي.
                  </li>
                  <li>
                    يعزز مكانة الصين كقوة رائدة في مجال التكنولوجيا الكمومية.
                  </li>
                </ul>
                <br />
                يعتبر إطلاق ميكيوس إنجازًا كبيرًا للصين، ويشير إلى طموحاتها في
                أن تصبح رائدة عالميًا في مجال التكنولوجيا الكمومية.
              </p>
            </div>

            <div className="news__item">
              <h2>
                أبل تصبح أول شركة أمريكية تتجاوز قيمتها السوقية تريليون دولار -
                2 أغسطس 2018
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
                alt="Apple logo"
                width="300"
              />
              <p>
                <span>
                  في أغسطس 2018، حققت شركة أبل إنجازًا تاريخيًا بوصول قيمتها
                  السوقية إلى تريليون دولار، لتصبح بذلك أول شركة أمريكية تحقق
                  هذا الرقم القياسي.
                </span>
                يعكس هذا الإنجاز النجاح الكبير الذي حققته أبل في السنوات
                الأخيرة، بفضل منتجاتها وخدماتها المبتكرة، مثل آيفون وآيباد وماك.
                <br />
                <br />
                <strong>أسباب هذا النجاح:</strong>
                <ul>
                  <li>تركيز أبل على التصميم والجودة وسهولة الاستخدام.</li>
                  <li>قدرتها على بناء نظام بيئي متكامل من الأجهزة والخدمات.</li>
                  <li>ولاء عملائها القوي.</li>
                </ul>
                <br />
                يعتبر وصول أبل إلى قيمة سوقية تريليون دولار علامة فارقة في تاريخ
                الشركة وقطاع التكنولوجيا بشكل عام، ويؤكد مكانتها كواحدة من أكثر
                الشركات نفوذاً في العالم.
              </p>
            </div>

            <div className="news__item">
              <h2>
                اليونان تتوصل إلى اتفاق مع الدائنين الدوليين بشأن حزمة إنقاذ
                جديدة - 13 يوليو 2015
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg"
                alt="Flag of Greece"
                width="300"
              />
              <p>
                <span>
                  في يوليو 2015، وبعد مفاوضات طويلة وصعبة، توصلت اليونان إلى
                  اتفاق مع الدائنين الدوليين (الاتحاد الأوروبي وصندوق النقد
                  الدولي والبنك المركزي الأوروبي) بشأن حزمة إنقاذ جديدة بقيمة 86
                  مليار يورو.
                </span>
                كان هذا الاتفاق ضروريًا لتجنب خروج اليونان من منطقة اليورو،
                وإعادة الاستقرار إلى اقتصادها المتعثر.
                <br />
                <br />
                <strong>شروط حزمة الإنقاذ:</strong>
                <ul>
                  <li>
                    تضمنت الحزمة إجراءات تقشفية صارمة، مثل خفض الإنفاق الحكومي
                    وزيادة الضرائب وإصلاح نظام التقاعد.
                  </li>
                </ul>
                <br />
                <strong>التحديات:</strong>
                <ul>
                  <li>
                    أثارت هذه الإجراءات احتجاجات واسعة النطاق في اليونان، حيث
                    اعتبرها الكثيرون غير عادلة ومؤلمة.
                  </li>
                  <li>
                    كما أن هناك شكوك حول قدرة اليونان على تنفيذ هذه الإصلاحات
                    وتحقيق النمو الاقتصادي اللازم لسداد ديونها.
                  </li>
                </ul>
                <br />
                على الرغم من هذه التحديات، كان اتفاق الإنقاذ خطوة مهمة نحو
                استعادة الاستقرار المالي في اليونان ومنطقة اليورو ككل.
              </p>
            </div>

            <div className="news__item">
              <h2>
                شركة علي بابا الصينية تطرح أكبر اكتتاب عام أولي في التاريخ - 19
                سبتمبر 2014
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Alibaba_Group_Logo.svg"
                alt="Alibaba Group logo"
                width="300"
              />
              <p>
                <span>
                  في سبتمبر 2014، حققت شركة علي بابا الصينية، عملاق التجارة
                  الإلكترونية، إنجازًا تاريخيًا بطرحها لأكبر اكتتاب عام أولي
                  (IPO) في التاريخ.
                </span>
                جمعت الشركة 25 مليار دولار من خلال بيع أسهمها في بورصة نيويورك،
                مما قيم الشركة بأكثر من 230 مليار دولار.
                <br />
                <br />
                <strong>أهمية هذا الاكتتاب:</strong>
                <ul>
                  <li>
                    عكس النمو الهائل للتجارة الإلكترونية في الصين، والقدرة
                    الكبيرة لشركات التكنولوجيا الصينية على جذب الاستثمارات
                    العالمية.
                  </li>
                  <li>
                    جعل علي بابا واحدة من أكبر الشركات في العالم من حيث القيمة
                    السوقية.
                  </li>
                </ul>
                <br />
                أثار هذا الاكتتاب حماسًا كبيرًا في الأسواق العالمية، واعتبر
                علامة فارقة في صعود الصين كقوة اقتصادية وتكنولوجية عالمية.
              </p>
            </div>

            <div className="news__item">
              <h2>
                الاحتياطي الفيدرالي الأمريكي يبدأ في تقليص برنامج شراء السندات -
                18 ديسمبر 2013
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/US-FederalReserveSystem-Seal.svg/1200px-US-FederalReserveSystem-Seal.svg.png"
                alt="Federal Reserve System seal"
                width="300"
              />
              <p>
                <span>
                  في ديسمبر 2013، أعلن الاحتياطي الفيدرالي الأمريكي عن بدء تقليص
                  برنامج شراء السندات الذي كان قد أطلقه في أعقاب الأزمة المالية
                  العالمية عام 2008.
                </span>
                كان هذا البرنامج، المعروف باسم "التيسير الكمي"، يهدف إلى دعم
                الاقتصاد الأمريكي من خلال ضخ السيولة في النظام المالي وخفض أسعار
                الفائدة طويلة الأجل.
                <br />
                <br />
                <strong>أسباب هذا القرار:</strong>
                <ul>
                  <li>
                    اعتبر الاحتياطي الفيدرالي أن الاقتصاد الأمريكي قد تعافى بشكل
                    كافٍ، وأنه لم يعد بحاجة إلى هذا الدعم الاستثنائي.
                  </li>
                </ul>
                <br />
                <strong>التأثير على الأسواق:</strong>
                <ul>
                  <li>
                    أدى هذا القرار إلى ارتفاع عوائد سندات الخزانة الأمريكية،
                    وانخفاض أسعار الذهب، وتقلبات في أسواق الأسهم العالمية.
                  </li>
                </ul>
                <br />
                كان قرار تقليص برنامج شراء السندات خطوة مهمة نحو تطبيع السياسة
                النقدية في الولايات المتحدة، ولكنه أثار أيضًا مخاوف بشأن تأثيره
                على النمو الاقتصادي العالمي.
              </p>
            </div>

            <div className="news__item">
              <h2>
                الاحتياطي الفيدرالي الأمريكي يطلق برنامج التيسير الكمي الثالث -
                13 سبتمبر 2012
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/US-FederalReserveSystem-Seal.svg/1200px-US-FederalReserveSystem-Seal.svg.png"
                alt="Federal Reserve System seal"
                width="300"
              />
              <p>
                <span>
                  في سبتمبر 2012، أطلق الاحتياطي الفيدرالي الأمريكي برنامج
                  التيسير الكمي الثالث، المعروف باسم "QE3".
                </span>
                في إطار هذا البرنامج، بدأ البنك المركزي بشراء سندات الخزانة
                والأوراق المالية المدعومة بالرهن العقاري بكميات كبيرة، بهدف دعم
                الاقتصاد الأمريكي وخفض معدلات البطالة.
                <br />
                <br />
                <strong>أهداف البرنامج:</strong>
                <ul>
                  <li>
                    دعم الاقتصاد الأمريكي الذي كان لا يزال يعاني من آثار الأزمة
                    المالية العالمية.
                  </li>
                  <li>خفض معدلات البطالة المرتفعة.</li>
                </ul>
                <br />
                <strong>التأثير:</strong>
                <ul>
                  <li>
                    أدى هذا البرنامج إلى انخفاض أسعار الفائدة طويلة الأجل،
                    وارتفاع أسعار الأسهم، وضعف الدولار الأمريكي.
                  </li>
                </ul>
                <br />
                كان برنامج التيسير الكمي الثالث أحد أهم الأدوات التي استخدمها
                الاحتياطي الفيدرالي لمواجهة الأزمة المالية وتحفيز النمو
                الاقتصادي في الولايات المتحدة.
              </p>
            </div>

            <div className="news__item">
              <h2>
                البابا فرانسيس يصبح أول بابا من أمريكا اللاتينية - 13 مارس 2013
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/45/Pope_Francis_-_April_2013.jpg"
                alt="Pope Francis"
                width="300"
              />
              <p>
                <span>
                  في 13 مارس 2013، انتخب الكاردينال خورخي ماريو بيرجوليو من
                  الأرجنتين بابا للكنيسة الكاثوليكية، واتخذ اسم البابا فرانسيس.
                </span>
                كان هذا الحدث تاريخيًا، حيث أصبح فرانسيس أول بابا من أمريكا
                اللاتينية، وأول بابا من خارج أوروبا منذ أكثر من 1200 عام.
                <br />
                <br />
                <strong>أهمية هذا الحدث:</strong>
                <ul>
                  <li>
                    عكس انتخاب البابا فرانسيس التحول الديموغرافي في الكنيسة
                    الكاثوليكية، حيث أصبحت أمريكا اللاتينية موطنًا لأكبر عدد من
                    الكاثوليك في العالم.
                  </li>
                  <li>
                    أعرب البابا فرانسيس عن اهتمامه بقضايا الفقراء والمهمشين،
                    ودعا إلى إصلاح الكنيسة.
                  </li>
                </ul>
                <br />
                يعتبر البابا فرانسيس شخصية مؤثرة على الساحة العالمية، وقد اتخذ
                مواقف جريئة بشأن قضايا مثل تغير المناخ والهجرة والعدالة
                الاجتماعية.
              </p>
            </div>

            <div className="news__item">
              <h2>
                باراك أوباما يفوز بفترة رئاسية ثانية في الولايات المتحدة - 6
                نوفمبر 2012
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/8/8d/President_Barack_Obama.jpg"
                alt="Barack Obama"
                width="300"
              />
              <p>
                <span>
                  في 6 نوفمبر 2012، فاز باراك أوباما بفترة رئاسية ثانية في
                  الولايات المتحدة، متغلبًا على منافسه الجمهوري ميت رومني.
                </span>
                كان هذا الفوز انتصارًا كبيرًا للديمقراطيين، وأكد شعبية أوباما
                بين الناخبين الأمريكيين.
                <br />
                <br />
                <strong>أهمية هذا الحدث:</strong>
                <ul>
                  <li>
                    أكد فوز أوباما مجددًا التزام الولايات المتحدة بالسياسات
                    التقدمية، بما في ذلك الرعاية الصحية الشاملة وحماية البيئة.
                  </li>
                  <li>
                    كما مثل هذا الفوز انتصارًا للتعددية الثقافية في الولايات
                    المتحدة، حيث أصبح أوباما أول رئيس أمريكي من أصل أفريقي.
                  </li>
                </ul>
                <br />
                خلال فترة ولايته الثانية، واجه أوباما تحديات كبيرة، بما في ذلك
                التعافي من الأزمة المالية العالمية، وتنفيذ قانون الرعاية الصحية،
                والتعامل مع الصراعات في الشرق الأوسط. ومع ذلك، ترك أوباما إرثًا
                مهمًا في مجالات السياسة الداخلية والخارجية، ولا يزال شخصية مؤثرة
                في السياسة الأمريكية والعالمية.
              </p>
            </div>

            <div className="news__item">
              <h2>
                احتجاجات "Occupy Wall Street" تجتاح الولايات المتحدة والعالم -
                17 سبتمبر 2011
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/3/35/Occupy_Wall_Street_protest_in_Liberty_Square%2C_Manhattan%2C_New_York_City.jpg"
                alt="Occupy Wall Street protest"
                width="300"
              />
              <p>
                <span>
                  في سبتمبر 2011، اندلعت احتجاجات "احتلوا وول ستريت" في مدينة
                  نيويورك، وسرعان ما انتشرت إلى مدن أخرى في الولايات المتحدة
                  وحول العالم.
                </span>
                كان المتظاهرون، الذين ينتمون إلى خلفيات متنوعة، يعبرون عن غضبهم
                من عدم المساواة الاقتصادية وجشع الشركات الكبرى وتأثير المال على
                السياسة.
                <br />
                <br />
                <strong>أهداف الاحتجاجات:</strong>
                <ul>
                  <li>إصلاح النظام المالي.</li>
                  <li>معالجة مشكلة البطالة.</li>
                  <li>تخفيف عبء الديون الطلابية.</li>
                  <li>مكافحة الفساد السياسي.</li>
                </ul>
                <br />
                على الرغم من أن الاحتجاجات لم تحقق جميع أهدافها، إلا أنها نجحت
                في تسليط الضوء على مشكلة عدم المساواة الاقتصادية، وأثرت على
                النقاش العام حول دور الشركات الكبرى في المجتمع.
              </p>
            </div>

            <div className="news__item">
              <h2>
                اليابان تتعرض لزلزال وتسونامي مدمرين يؤديان إلى كارثة نووية في
                فوكوشيما - 11 مارس 2011
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/f/f7/Fukushima_Daiichi_Nuclear_Power_Plant_aerial_view_after_hydrogen_explosion.jpg"
                alt="Fukushima nuclear disaster"
                width="300"
              />
              <p>
                <span>
                  في 11 مارس 2011، تعرضت اليابان لزلزال وتسونامي مدمرين تسببا في
                  كارثة نووية في محطة فوكوشيما دايتشي للطاقة النووية.
                </span>
                كانت هذه الكارثة هي الأسوأ من نوعها منذ كارثة تشيرنوبيل عام
                1986، وأدت إلى إجلاء مئات الآلاف من السكان وتلوث مساحات واسعة من
                الأراضي والمياه.
                <br />
                <br />
                <strong>التأثير الاقتصادي:</strong>
                <ul>
                  <li>
                    تسببت الكارثة في خسائر اقتصادية فادحة لليابان، حيث قدرت
                    الأضرار بمئات المليارات من الدولارات.
                  </li>
                  <li>
                    أدت إلى توقف العديد من المصانع والشركات، وتعطيل سلاسل
                    التوريد العالمية.
                  </li>
                  <li>
                    أثارت مخاوف بشأن سلامة الطاقة النووية، وأدت إلى إعادة تقييم
                    العديد من البلدان لاستراتيجياتها في مجال الطاقة.
                  </li>
                </ul>
                <br />
                لا تزال اليابان تتعامل مع تداعيات كارثة فوكوشيما، وتعمل على
                إزالة التلوث وإعادة بناء المناطق المتضررة.
              </p>
            </div>

            <div className="news__item">
              <h2>
                الصين تتجاوز اليابان لتصبح ثاني أكبر اقتصاد في العالم - 2010
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg"
                alt="Flag of China"
                width="300"
              />
              <p>
                <span>
                  في عام 2010، حققت الصين إنجازًا تاريخيًا بتجاوزها اليابان
                  لتصبح ثاني أكبر اقتصاد في العالم بعد الولايات المتحدة.
                </span>
                يعكس هذا الإنجاز النمو الاقتصادي السريع الذي شهدته الصين في
                العقود الأخيرة، بفضل الإصلاحات الاقتصادية والانفتاح على التجارة
                والاستثمار الأجنبي.
                <br />
                <br />
                <strong>أهمية هذا الحدث:</strong>
                <ul>
                  <li>
                    أكد صعود الصين كقوة اقتصادية عالمية، وأدى إلى زيادة نفوذها
                    في الشؤون الدولية.
                  </li>
                  <li>
                    أدى إلى تحولات كبيرة في الاقتصاد العالمي، حيث أصبحت الصين
                    سوقًا رئيسية للعديد من السلع والخدمات، ومصدرًا رئيسيًا
                    للاستثمار الأجنبي.
                  </li>
                </ul>
                <br />
                لا يزال الاقتصاد الصيني ينمو بوتيرة سريعة، ويتوقع البعض أن
                يتجاوز اقتصاد الولايات المتحدة في المستقبل القريب. ومع ذلك،
                تواجه الصين أيضًا تحديات كبيرة، بما في ذلك ارتفاع مستويات الديون
                وشيخوخة السكان وعدم المساواة في الدخل.
              </p>
            </div>

            <div className="news__item">
              <h2>
                اليونان تتخلف عن سداد ديونها وتتسبب في أزمة منطقة اليورو - 2010
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg"
                alt="Flag of Greece"
                width="300"
              />
              <p>
                <span>
                  في عام 2010، هزّت اليونان أركان منطقة اليورو بتخلفها عن سداد
                  ديونها السيادية، مما أدى إلى اندلاع أزمة مالية حادة في
                  المنطقة.
                </span>
                كانت اليونان تعاني من ارتفاع معدلات الدين العام والعجز المالي،
                وتفاقمت مشاكلها بسبب الأزمة المالية العالمية عام 2008.
                <br />
                <br />
                <strong>تأثير الأزمة:</strong>
                <ul>
                  <li>
                    أدى تخلف اليونان عن السداد إلى ارتفاع تكاليف الاقتراض للدول
                    الأخرى في منطقة اليورو، وأثار مخاوف بشأن قدرتها على سداد
                    ديونها.
                  </li>
                  <li>
                    تسببت الأزمة في انكماش اقتصادي حاد في اليونان، وارتفاع
                    معدلات البطالة، وتدهور مستوى المعيشة.
                  </li>
                  <li>
                    اضطرت اليونان إلى قبول حزم إنقاذ مالية مشروطة بإجراءات
                    تقشفية صارمة، مما أثار احتجاجات واسعة النطاق في البلاد.
                  </li>
                </ul>
                <br />
                أزمة الديون اليونانية كانت بمثابة اختبار حقيقي لتماسك منطقة
                اليورو، وأدت إلى اتخاذ إجراءات لتعزيز التكامل المالي والنقدي في
                المنطقة.
              </p>
            </div>

            <div className="news__item">
              <h2>
                شركة "BP" النفطية تتسبب في أكبر تسرب نفطي في تاريخ الولايات
                المتحدة في خليج المكسيك - 20 أبريل 2010
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Deepwater_Horizon_offshore_drilling_unit_on_fire_2010.jpg/1200px-Deepwater_Horizon_offshore_drilling_unit_on_fire_2010.jpg"
                alt="Deepwater Horizon oil spill"
                width="300"
              />
              <p>
                <span>
                  في أبريل 2010، وقع انفجار على منصة الحفر البحرية "ديب ووتر
                  هورايزون" التابعة لشركة BP في خليج المكسيك، مما أدى إلى أكبر
                  تسرب نفطي في تاريخ الولايات المتحدة.
                </span>
                استمر التسرب لأشهر، وتسبب في أضرار بيئية واقتصادية هائلة في
                المنطقة.
                <br />
                <br />
                <strong>التأثير البيئي والاقتصادي:</strong>
                <ul>
                  <li>
                    تلوث مساحات شاسعة من المياه والسواحل، مما أثر على الحياة
                    البحرية وصيد الأسماك والسياحة.
                  </li>
                  <li>
                    خسائر اقتصادية فادحة لشركات صيد الأسماك والسياحة والمجتمعات
                    المحلية في المنطقة.
                  </li>
                  <li>
                    دفعت BP مليارات الدولارات كتعويضات عن الأضرار البيئية
                    والاقتصادية.
                  </li>
                </ul>
                <br />
                أثارت كارثة التسرب النفطي تساؤلات حول سلامة عمليات الحفر
                البحرية، وأدت إلى تشديد اللوائح البيئية في الولايات المتحدة. كما
                أضرت بسمعة شركة BP بشكل كبير.
              </p>
            </div>

            <div className="news__item">
              <h2>
                دبي تعلن عن تأجيل سداد ديونها وتتسبب في مخاوف بشأن الاستقرار
                المالي العالمي - 25 نوفمبر 2009
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/8/82/Burj_Khalifa_and_Dubai_Downtown_at_dusk.jpg"
                alt="Dubai skyline"
                width="300"
              />
              <p>
                <span>
                  في نوفمبر 2009، أعلنت شركة "دبي العالمية"، وهي شركة استثمارية
                  مملوكة لحكومة دبي، عن طلبها لتأجيل سداد ديونها المستحقة.
                </span>
                بلغت قيمة الديون التي طلبت الشركة تأجيل سدادها حوالي 26 مليار
                دولار، مما أثار مخاوف بشأن قدرة دبي على الوفاء بالتزاماتها
                المالية.
                <br />
                <br />
                <strong>تأثير الإعلان:</strong>
                <ul>
                  <li>
                    أدى هذا الإعلان إلى هزة في الأسواق المالية العالمية، حيث
                    انخفضت أسعار الأسهم وارتفعت تكاليف التأمين على الديون
                    السيادية لدبي.
                  </li>
                  <li>
                    أثار مخاوف بشأن استقرار النظام المالي العالمي، خاصة في ظل
                    التداعيات المستمرة للأزمة المالية العالمية.
                  </li>
                </ul>
                <br />
                على الرغم من أن حكومة دبي تدخلت في النهاية لتقديم الدعم المالي
                لشركة دبي العالمية، إلا أن هذه الأزمة سلطت الضوء على المخاطر
                المرتبطة بالنمو السريع القائم على الديون، وأهمية إدارة المخاطر
                المالية بشكل فعال.
              </p>
            </div>

            <div className="news__item">
              <h2>
                ساتوشي ناكاموتو ينشر الورقة البيضاء لبيتكوين - 31 أكتوبر 2008
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg"
                alt="Bitcoin logo"
                width="300"
              />
              <p>
                <span>
                  في 31 أكتوبر 2008، نشر شخص أو مجموعة من الأشخاص مجهولين،
                  يعرفون باسم ساتوشي ناكاموتو، ورقة بيضاء بعنوان "Bitcoin: A
                  Peer-to-Peer Electronic Cash System".
                </span>
                قدمت هذه الورقة البيضاء مفهومًا جديدًا للعملة الرقمية
                اللامركزية، والتي تعمل على شبكة بلوك تشين ولا تخضع لسيطرة أي
                حكومة أو مؤسسة مالية.
                <br />
                <br />
                <strong>أهمية هذا الحدث:</strong>
                <ul>
                  <li>
                    يعتبر نشر هذه الورقة البيضاء بمثابة ولادة البيتكوين، وهي أول
                    وأشهر عملة رقمية في العالم.
                  </li>
                  <li>
                    أدى إلى ظهور تكنولوجيا البلوك تشين، التي لها تطبيقات محتملة
                    في العديد من المجالات، بما في ذلك الخدمات المالية وسلاسل
                    التوريد والرعاية الصحية.
                  </li>
                </ul>
                <br />
                على الرغم من أن البيتكوين والعملات الرقمية الأخرى لا تزال مثيرة
                للجدل، إلا أنها أصبحت جزءًا لا يتجزأ من المشهد المالي العالمي،
                وأثارت نقاشًا واسعًا حول مستقبل النقود والتمويل.
              </p>
            </div>

            <div className="news__item">
              <h2>
                انهيار بنك "ليمان براذرز" يؤدي إلى أزمة مالية عالمية - 15 سبتمبر
                2008
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Lehman_Brothers_logo.svg/1200px-Lehman_Brothers_logo.svg.png"
                alt="Lehman Brothers logo"
                width="300"
              />
              <p>
                <span>
                  في 15 سبتمبر 2008، أعلن بنك "ليمان براذرز"، وهو أحد أكبر
                  البنوك الاستثمارية في العالم، إفلاسه، مما أدى إلى اندلاع أزمة
                  مالية عالمية.
                </span>
                كان هذا الانهيار هو الأكبر في تاريخ الولايات المتحدة، وتسبب في
                خسائر فادحة للمستثمرين والمؤسسات المالية في جميع أنحاء العالم.
                <br />
                <br />
                <strong>أسباب الانهيار:</strong>
                <ul>
                  <li>
                    كان البنك قد استثمر بشكل كبير في الأوراق المالية المدعومة
                    بالرهن العقاري، والتي انخفضت قيمتها بشكل حاد في أعقاب أزمة
                    الرهن العقاري عالية المخاطر.
                  </li>
                  <li>
                    عندما حاول البنك بيع هذه الأصول لتلبية التزاماته المالية، لم
                    يجد مشترين، مما أدى إلى انهياره.
                  </li>
                </ul>
                <br />
                <strong>تأثير الأزمة:</strong>
                <ul>
                  <li>
                    أدى انهيار ليمان براذرز إلى تجميد أسواق الائتمان العالمية،
                    وتسبب في انهيار العديد من البنوك والمؤسسات المالية الأخرى.
                  </li>
                  <li>
                    دفعت الحكومات في جميع أنحاء العالم إلى التدخل لإنقاذ النظام
                    المالي، من خلال ضخ تريليونات الدولارات في البنوك وشراء
                    الأصول المتعثرة.
                  </li>
                  <li>
                    تسببت الأزمة في ركود اقتصادي عالمي حاد، وارتفاع معدلات
                    البطالة، وتدهور مستوى المعيشة للعديد من الناس.
                  </li>
                </ul>
                <br />
                لا تزال آثار الأزمة المالية العالمية محسوسة حتى اليوم، حيث أدت
                إلى تشديد اللوائح المالية وزيادة الرقابة على البنوك والمؤسسات
                المالية.
              </p>
            </div>

            <div className="news__item">
              <h2>
                الصين تتجاوز اليابان لتصبح ثاني أكبر اقتصاد في العالم - 2010
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg"
                alt="Flag of China"
                width="300"
              />
              <p>
                <span>
                  في عام 2010، حققت الصين إنجازًا تاريخيًا بتجاوزها اليابان
                  لتصبح ثاني أكبر اقتصاد في العالم بعد الولايات المتحدة.
                </span>
                يعكس هذا الإنجاز النمو الاقتصادي السريع الذي شهدته الصين في
                العقود الأخيرة، بفضل الإصلاحات الاقتصادية والانفتاح على التجارة
                والاستثمار الأجنبي.
                <br />
                <br />
                <strong>أهمية هذا الحدث:</strong>
                <ul>
                  <li>
                    أكد صعود الصين كقوة اقتصادية عالمية، وأدى إلى زيادة نفوذها
                    في الشؤون الدولية.
                  </li>
                  <li>
                    أدى إلى تحولات كبيرة في الاقتصاد العالمي، حيث أصبحت الصين
                    سوقًا رئيسية للعديد من السلع والخدمات، ومصدرًا رئيسيًا
                    للاستثمار الأجنبي.
                  </li>
                </ul>
                <br />
                لا يزال الاقتصاد الصيني ينمو بوتيرة سريعة، ويتوقع البعض أن
                يتجاوز اقتصاد الولايات المتحدة في المستقبل القريب. ومع ذلك،
                تواجه الصين أيضًا تحديات كبيرة، بما في ذلك ارتفاع مستويات الديون
                وشيخوخة السكان وعدم المساواة في الدخل.
              </p>
            </div>

            <div className="news__item">
              <h2>
                أزمة الرهن العقاري عالية المخاطر تؤدي إلى انهيار بنك "Bear
                Stearns" - 16 مارس 2008
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Bear-stearns-logo.svg/1200px-Bear-stearns-logo.svg.png"
                alt="Bear Stearns logo"
                width="300"
              />
              <p>
                <span>
                  في مارس 2008، انهار بنك "بير ستيرنز"، وهو أحد أكبر البنوك
                  الاستثمارية في الولايات المتحدة، بعد أن تكبد خسائر فادحة بسبب
                  أزمة الرهن العقاري عالية المخاطر.
                </span>
                كان هذا الانهيار هو الأول في سلسلة من الأحداث التي أدت إلى
                الأزمة المالية العالمية عام 2008.
                <br />
                <br />
                <strong>أسباب الانهيار:</strong>
                <ul>
                  <li>
                    كان البنك قد استثمر بشكل كبير في الأوراق المالية المدعومة
                    بالرهن العقاري، والتي انخفضت قيمتها بشكل حاد في أعقاب أزمة
                    الرهن العقاري.
                  </li>
                  <li>
                    عندما حاول البنك بيع هذه الأصول لتلبية التزاماته المالية، لم
                    يجد مشترين، مما أدى إلى انهياره.
                  </li>
                </ul>
                <br />
                <strong>تأثير الانهيار:</strong>
                <ul>
                  <li>
                    أدى انهيار بير ستيرنز إلى زيادة المخاوف بشأن استقرار النظام
                    المالي الأمريكي، وأثار حالة من الذعر في الأسواق.
                  </li>
                  <li>
                    دفع هذا الانهيار الاحتياطي الفيدرالي الأمريكي إلى التدخل
                    لتقديم قروض طارئة للبنوك الأخرى، ومنع حدوث أزمة مالية أوسع
                    نطاقًا.
                  </li>
                </ul>
              </p>
            </div>

            <div className="news__item">
              <h2>
                دونالد ترامب يفوز في الانتخابات الرئاسية الأمريكية - 8 نوفمبر
                2016
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Donald_Trump_official_portrait.jpg/1200px-Donald_Trump_official_portrait.jpg"
                alt="Donald Trump"
                width="300"
              />
              <p>
                <span>
                  في 8 نوفمبر 2016، فاز دونالد ترامب، رجل الأعمال والملياردير
                  الأمريكي، في الانتخابات الرئاسية الأمريكية، متغلبًا على
                  منافسته الديمقراطية هيلاري كلينتون.
                </span>
                كان هذا الفوز مفاجأة للكثيرين، حيث كانت استطلاعات الرأي تشير إلى
                تقدم كلينتون.
                <br />
                <br />
                <strong>تأثير الفوز على الأسواق:</strong>
                <ul>
                  <li>
                    أدى فوز ترامب إلى تقلبات في الأسواق المالية العالمية، حيث
                    كان المستثمرون قلقين بشأن سياساته الاقتصادية غير التقليدية.
                  </li>
                  <li>
                    ومع ذلك، ارتفعت أسواق الأسهم الأمريكية بعد فوزه، حيث توقع
                    المستثمرون أن سياساته ستؤدي إلى خفض الضرائب وزيادة الإنفاق
                    على البنية التحتية.
                  </li>
                </ul>
                <br />
                خلال فترة رئاسته، نفذ ترامب عددًا من السياسات الاقتصادية المثيرة
                للجدل، بما في ذلك خفض الضرائب على الشركات والأثرياء، وفرض
                تعريفات جمركية على الواردات الصينية، وسحب الولايات المتحدة من
                اتفاقية باريس للمناخ. وقد أدت هذه السياسات إلى نمو اقتصادي قوي
                في الولايات المتحدة، ولكنها أثارت أيضًا مخاوف بشأن ارتفاع العجز
                المالي وتفاقم عدم المساواة في الدخل.
              </p>
            </div>

            <div className="news__item">
              <h2>شركة آبل تطلق أول هاتف آيفون - 9 يناير 2007</h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6s/IPhone_3GS.svg/1200px-IPhone_3GS.svg.png"
                alt="First generation iPhone"
                width="300"
              />
              <p>
                <span>
                  في يناير 2007، كشفت شركة أبل النقاب عن أول هاتف آيفون، وهو
                  جهاز ثوري غيّر وجه صناعة الهواتف المحمولة إلى الأبد.
                </span>
                جمع الآيفون بين وظائف الهاتف المحمول والكاميرا الرقمية وجهاز
                iPod في جهاز واحد أنيق وسهل الاستخدام.
                <br />
                <br />
                <strong>تأثير إطلاق الآيفون:</strong>
                <ul>
                  <li>
                    أحدث ثورة في صناعة الهواتف المحمولة، وأدى إلى ظهور عصر
                    الهواتف الذكية.
                  </li>
                  <li>
                    غيّر طريقة تفاعل الناس مع التكنولوجيا، وفتح الباب أمام
                    مجموعة واسعة من التطبيقات والخدمات الجديدة.
                  </li>
                  <li>
                    عزز مكانة أبل كشركة رائدة في مجال الابتكار التكنولوجي.
                  </li>
                </ul>
                <br />
                يعتبر إطلاق الآيفون أحد أهم الأحداث في تاريخ التكنولوجيا، ولا
                يزال تأثيره محسوسًا حتى اليوم.
              </p>
            </div>

            <div className="news__item">
              <h2>وفاة الرئيس العراقي السابق صدام حسين - 30 ديسمبر 2006</h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Saddam_Hussein_mugshot.jpg/1200px-Saddam_Hussein_mugshot.jpg"
                alt="Saddam Hussein"
                width="300"
              />
              <p>
                <span>
                  في 30 ديسمبر 2006، تم إعدام الرئيس العراقي السابق صدام حسين
                  شنقًا بعد إدانته بارتكاب جرائم ضد الإنسانية.
                </span>
                جاء إعدام صدام بعد غزو العراق بقيادة الولايات المتحدة عام 2003،
                والإطاحة بنظامه.
                <br />
                <br />
                <strong>تأثير إعدام صدام:</strong>
                <ul>
                  <li>
                    أثار إعدام صدام ردود فعل متباينة في جميع أنحاء العالم، حيث
                    اعتبره البعض نهاية لحكم ديكتاتوري قمعي، في حين رأى آخرون أنه
                    يمثل انتهاكًا للسيادة العراقية.
                  </li>
                  <li>
                    أدى إعدام صدام إلى فراغ في السلطة في العراق، وتسبب في تصاعد
                    العنف الطائفي.
                  </li>
                  <li>
                    لا تزال تداعيات إعدام صدام محسوسة في العراق حتى اليوم، حيث
                    يعاني البلد من عدم الاستقرار السياسي والأمني.
                  </li>
                </ul>
              </p>
            </div>

            <div className="news__item">
              <h2>
                إعصار كاترينا يدمر مدينة نيو أورلينز الأمريكية - 29 أغسطس 2005
              </h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/0/05/Hurricane_katrina_damage.jpg"
                alt="Hurricane Katrina aftermath"
                width="300"
              />
              <p>
                <span>
                  في أغسطس 2005، ضرب إعصار كاترينا ساحل الخليج الأمريكي، مسببًا
                  دمارًا واسعًا في مدينة نيو أورلينز ولويزيانا.
                </span>
                تسبب الإعصار في فيضانات كارثية، مما أدى إلى مقتل أكثر من 1800
                شخص وتشريد مئات الآلاف.
                <br />
                <br />
                <strong>تأثير الإعصار:</strong>
                <ul>
                  <li>
                    تسبب في خسائر اقتصادية فادحة، حيث قدرت الأضرار بأكثر من 170
                    مليار دولار.
                  </li>
                  <li>
                    أدى إلى أزمة إنسانية كبيرة، حيث عانى الكثيرون من نقص الغذاء
                    والماء والمأوى.
                  </li>
                  <li>
                    أثار انتقادات واسعة النطاق لطريقة تعامل الحكومة الأمريكية مع
                    الكارثة.
                  </li>
                </ul>
                <br />
                لا تزال نيو أورلينز تتعافى من آثار إعصار كاترينا، ولكنها تواجه
                تحديات كبيرة في إعادة بناء البنية التحتية واستعادة اقتصادها.
              </p>
            </div>

            <div className="news__item">
              <h2>شركة جوجل تطرح اكتتابها العام الأولي - 19 أغسطس 2004</h2>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg"
                alt="Google logo"
                width="300"
              />
              <p>
                <span>
                  في أغسطس 2004، طرحت شركة جوجل، عملاق محركات البحث على
                  الإنترنت، اكتتابها العام الأولي (IPO) في بورصة ناسداك.
                </span>
                جمعت الشركة 1.67 مليار دولار من خلال بيع أسهمها، مما قيم الشركة
                بأكثر من 23 مليار دولار.
                <br />
                <br />
                <strong>أهمية هذا الاكتتاب:</strong>
                <ul>
                  <li>
                    كان واحدًا من أكبر الاكتتابات العامة الأولية في تاريخ قطاع
                    التكنولوجيا.
                  </li>
                  <li>
                    جعل جوجل واحدة من أكبر الشركات في العالم من حيث القيمة
                    السوقية.
                  </li>
                  <li>
                    أتاح للشركة الحصول على رأس المال اللازم للتوسع وتطوير منتجات
                    وخدمات جديدة.
                  </li>
                </ul>
                <br />
                يعتبر اكتتاب جوجل العام الأولي حدثًا تاريخيًا في تطور الشركة
                وقطاع التكنولوجيا بشكل عام، وقد مهد الطريق لنمو الشركة لتصبح
                واحدة من أكثر الشركات نفوذاً في العالم.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Calculator;

/* Provide today's top realtime not just an example 5-6 financial news headlines in Arabic, formatted as ReactJS-compatible HTML snippets.



Include relevant numerical details.

Maintain a factual, neutral tone.

Source images from royalty-free websites (e.g., Pexels, Unsplash, Pixabay) that are relevant to the news content.

Output only the following HTML structure for each news item, with no additional text or commentary:

<div className="news__item">

  <h2>{Headline} - {Current Date}</h2>

  <img src="{Royalty-free Image URL Relevant to News}" alt="{Image Description}" width="300" /> 

  <p>{News Content}</p>

</div>   */

/*
## الأحداث مرتبة من الأحدث إلى الأقدم:

1. **مؤشر ستاندرد آند بورز 500 يسجل أسوأ أسبوع له منذ مارس - 18 أغسطس 2023** 
2. **مجموعة "إيفرغراند" الصينية العملاقة للتطوير العقاري تتقدم بطلب لإشهار إفلاسها في الولايات المتحدة - 17 أغسطس 2023** 
4. **احتدام المخاوف بشأن تباطؤ النمو الاقتصادي الصيني - 15 أغسطس 2023** 
5. **الذهب يصل إلى أعلى مستوى له في شهر - 7 أغسطس 2023**
6. **موديز تخفض التصنيف الائتماني لعدد من البنوك الأمريكية الصغيرة والمتوسطة - 7 أغسطس 2023**
3. **حرائق غابات هاواي تدمر مدينة لاهاينا وتتسبب في خسائر فادحة - أغسطس 2023** 
8. **الاحتياطي الفيدرالي الأمريكي يرفع أسعار الفائدة إلى أعلى مستوى لها منذ 22 عامًا - 26 يوليو 2023** 
9. **الاحتياطي الفيدرالي الأمريكي يرفع أسعار الفائدة للمرة الحادية عشرة على التوالي - 26 يوليو 2023** 
10. **تراجع أسعار الغاز الطبيعي في أوروبا إلى أدنى مستوياتها منذ عام 2021 - 24 يوليو 2023** 
14. **الهند تتجاوز الصين لتصبح الدولة الأكثر سكانًا في العالم - 14 يونيو 2023** 
13. **بنك الاحتياطي الفيدرالي يوقف رفع أسعار الفائدة مؤقتًا - 14 يونيو 2023** 
12. **ارتفاع التضخم في الولايات المتحدة إلى أعلى مستوى له منذ 40 عامًا - 13 يوليو 2022** 
11. **ارتفاع أسعار النفط وسط توترات في الشرق الأوسط - 12 يوليو 2023**
7. **ميتا تطلق تطبيق ثريدز منافسًا لتويتر - 5 يوليو 2023** 
15. **أبل تكشف عن نظارة الواقع المختلط "Vision Pro" - 5 يونيو 2023** 
16. **ارتفاع أسعار النفط بعد قرار أوبك+ بتخفيض الإنتاج - 4 يونيو 2023**
17. **إقرار الكونجرس الأمريكي مشروع قانون سقف الدين العام - 3 يونيو 2023**
18. **الاحتياطي الفيدرالي الأمريكي يرفع أسعار الفائدة للمرة العاشرة على التوالي - 3 مايو 2023** 
19. **انهيار بنك "First Republic" الأمريكي - 1 مايو 2023** 
20. **تويتر يزيل علامة التحقق الزرقاء القديمة - 20 أبريل 2023** 
22. **صندوق النقد الدولي يحذر من تباطؤ النمو الاقتصادي العالمي - 11 أبريل 2023** 
21. **تراجع أسعار الذهب بعد بيانات اقتصادية قوية من الولايات المتحدة - 6 أبريل 2023**
23. **تراجع أسعار النفط بعد قرار أوبك+ بتخفيض الإنتاج بشكل مفاجئ - 2 أبريل 2023** 
25. **فيسبوك تغير اسمها إلى ميتا - 28 أكتوبر 2021** 
24. **إيلون ماسك يشتري تويتر مقابل 44 مليار دولار - 27 أكتوبر 2022** 
26. **حرب روسيا على أوكرانيا تؤدي إلى ارتفاع أسعار الطاقة والغذاء - 24 فبراير 2022** 
29. **الصين تفرض قيودًا صارمة على قطاع التكنولوجيا - 2020-2021** 
27. **خروج بريطانيا من الاتحاد الأوروبي (بريكست) - 31 يناير 2020**
28. **جائحة كورونا تتسبب في أكبر ركود اقتصادي عالمي منذ الكساد الكبير - 2020** 
30. **الاحتياطي الفيدرالي الأمريكي يخفض أسعار الفائدة إلى الصفر تقريبًا - 15 مارس 2020** 
31. **شركة أمازون تشتري متاجر "Whole Foods Market" - 28 أغسطس 2017**
32. **الصين تطلق أول قمر صناعي للاتصالات الكمومية - 16 أغسطس 2016** 
33. **أبل تصبح أول شركة أمريكية تتجاوز قيمتها السوقية تريليون دولار - 2 أغسطس 2018**
34. **اليونان تتوصل إلى اتفاق مع الدائنين الدوليين بشأن حزمة إنقاذ جديدة - 13 يوليو 2015** 
35. **شركة علي بابا الصينية تطرح أكبر اكتتاب عام أولي في التاريخ - 19 سبتمبر 2014**
36. **الاحتياطي الفيدرالي الأمريكي يبدأ في تقليص برنامج شراء السندات - 18 ديسمبر 2013** 
37. **الاحتياطي الفيدرالي الأمريكي يطلق برنامج التيسير الكمي الثالث - 13 سبتمبر 2012** 
38. **البابا فرانسيس يصبح أول بابا من أمريكا اللاتينية - 13 مارس 2013**
39. **باراك أوباما يفوز بفترة رئاسية ثانية في الولايات المتحدة - 6 نوفمبر 2012**
40. **احتجاجات "Occupy Wall Street" تجتاح الولايات المتحدة والعالم - 17 سبتمبر 2011**
41. **اليابان تتعرض لزلزال وتسونامي مدمرين يؤديان إلى كارثة نووية في فوكوشيما - 11 مارس 2011**
42. **الصين تتجاوز اليابان لتصبح ثاني أكبر اقتصاد في العالم - 2010** 
43. **اليونان تتخلف عن سداد ديونها وتتسبب في أزمة منطقة اليورو - 2010**
44. **شركة "BP" النفطية تتسبب في أكبر تسرب نفطي في تاريخ الولايات المتحدة في خليج المكسيك - 20 أبريل 2010**
45. **دبي تعلن عن تأجيل سداد ديونها وتتسبب في مخاوف بشأن الاستقرار المالي العالمي - 25 نوفمبر 2009**
46. **ساتوشي ناكاموتو ينشر الورقة البيضاء لبيتكوين - 31 أكتوبر 2008**
47. **انهيار بنك "ليمان براذرز" يؤدي إلى أزمة مالية عالمية - 15 سبتمبر 2008** 
48. **أزمة الرهن العقاري عالية المخاطر تؤدي إلى انهيار بنك "Bear Stearns" - 16 مارس 2008**
49. **دونالد ترامب يفوز في الانتخابات الرئاسية الأمريكية - 8 نوفمبر 2016**
50. **شركة آبل تطلق أول هاتف آيفون - 9 يناير 2007**
51. **وفاة الرئيس العراقي السابق صدام حسين - 30 ديسمبر 2006**
52. **المملكة المتحدة تصوت لصالح الخروج من الاتحاد الأوروبي (بريكست) - 23 يونيو 2016** 
53. **إعصار كاترينا يدمر مدينة نيو أورلينز الأمريكية - 29 أغسطس 2005**
54. **شركة جوجل تطرح اكتتابها العام الأولي - 19 أغسطس 2004**
55. **البنك المركزي الأوروبي يطلق برنامج شراء السندات - 9 مارس 2015** 
56. **حرب العراق تبدأ بغزو الولايات المتحدة للعراق - 20 مارس 2003**
57. **هجمات 11 سبتمبر الإرهابية على الولايات المتحدة - 11 سبتمبر 2001**
58. **الصين تنضم إلى منظمة التجارة العالمية - 11 ديسمبر 2001**
59. **انفجار فقاعة الإنترنت (دوت كوم) يصل إلى ذروته - 10 مارس 2000** 
60. **انفجار فقاعة الإنترنت (دوت كوم) - 2000-2002**
61. **إطلاق اليورو - 1 يناير 1999**
62. الأزمة المالية الآسيوية - 1997-1998
*/
