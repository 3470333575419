import React from "react";
import { Helmet } from "react-helmet-async";
import "./InvestmentCalculator.css";

class InvestmentCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      investment: 0,
      gain: 0,
      month: 0,
      result: 0,
      history: [],
    };
    this.investmentCalc = this.investmentCalc.bind(this);
  }

  investmentCalc() {
    var res = Number(this.state.investment);
    var gainPercent = this.state.gain / 100;
    for (var i = 1; i < this.state.month; i++) {
      res = res + res * gainPercent;
    }
    this.setState({ result: res });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>حاسبة الاستثمار</title>
          <meta name="description" content="الاستثمار هو استراتيجية مالية تجعل المال يعمل من أجلك" />
          <link rel="canonical" href="/InvestmentCalculator" />
        </Helmet>
        <div className="main-app">
          <div className="invstCalc">
            <h2>حاسبة الاستثمار</h2>
            <div>
              <h3>استثمار</h3>
              <input
                type="number"
                defaultValue={this.state.investment}
                onChange={(e) => this.setState({ investment: e.target.value })}
              ></input>
            </div>
            <div>
              <h3>نسبة الكسب</h3>
              <input
                type="number"
                defaultValue={this.state.gain}
                onChange={(e) => this.setState({ gain: e.target.value })}
              ></input>
            </div>
            <div>
              <h3>شهر</h3>
              <input
                type="number"
                defaultValue={this.state.month}
                onChange={(e) => this.setState({ month: e.target.value })}
              ></input>
            </div>
            <button className="submit" name="=" onClick={this.investmentCalc}>
              حسب
            </button>
            <p>{this.state.result}</p>
          </div>
          <h3>
            الاستثمار هو استراتيجية مالية تجعل المال يعمل من أجلك. الاستثمار
            يعني وضع أموالك في شيء على أمل جني الأموال منه لاحقًا. يحب معظم
            الناس استثمار أموالهم. يمكن أن يكون سهلاً وممتعًا عند القيام به بشكل
            صحيح. تقدم معظم البنوك والمؤسسات المالية حاسبات استثمار متنوعة
            لمساعدة عملائها على تحديد الاستثمارات التي يجب القيام بها. إن خسارة
            المال ليس خيارًا عند استخدام حاسبة الاستثمار. يجب اتخاذ قرارات
            الاستثمار بعناية ، حيث أن لها تأثيرًا مباشرًا على رصيد حسابك
            المصرفي. من المهم أن تثق بمستشارك المالي وأن تتخذ خيارات ذكية عند
            اختيار الاستثمارات. سترغب في اختيار استراتيجية ستمنحك أكبر عائد في
            أقصر فترة زمنية. بهذه الطريقة ، يمكنك سداد ديونك وتعيش الحياة التي
            تستحقها مع كسب دخل إضافي. مصباح علاء الدين يجلس فوق العديد من مواقد
            الجني في كهف ؛ ولكن لا يزال هناك شيء يجب التشبث به عندما يكون معظم
            الناس في الظلام يتعلق بجني الأموال. - - دروس آموزآتية مدرجية
            كالمنتدى الدولي ، عالم متخصص بالاسباب النقدية لا ينطبق عليه من خلال
            الهروب السياسي والاستثمارات السرية. وهو للفك واقع يعنيه هونق بسهولة
            في فك المال مع ضمانه لافعل ذلك على مرور السنين. يتم هذا من نتيجة
            البحر في مساواة شركات الاتصالات لتسهيل الاستثمار والاستثمارات
            والتباين والاطمسان معهم والتعامل معهم. - مقدمة: الاستثمار كلمة تشير
            إلى فعل استثمار أموالك أو مواردك في شيء على أمل جني الأموال منه
            لاحقا. هناك عدة أنواع مختلفة من الاستثمارات ، مثل الأسهم والسندات
            والعقارات أو تعدين الذهب. من الجيد تنويع استثماراتك حتى لا تخسر كل
            أموالك. بالإضافة إلى ذلك ، من الجيد عادةً أن يكون لديك تأمين على
            استثماراتك ، لذا إذا حدث شيء ما ، فأنت لست من كل ما عملت من أجله.
            عادة ما تمتلك البنوك أدوات رائعة لعملاء البنوك لاتخاذ قرارات
            استثمارية مدروسة. تساعدك حاسبة الاستثمار في حساب مقدار الأموال التي
            يجب أن تضعها في استثمارات مختلفة حتى تتمكن من تحقيق أكبر قدر ممكن من
            الأرباح. يجب ألا تضع المال في خطة الاستثمار إلا إذا كنت تعتقد أنها
            ستمنحك عائدًا جيدًا على أموالك. تحتاج أيضًا إلى أن يكون لديك أموال
            كافية في البنك لتغطية أي خسائر من استثماراتك. بشكل عام ، كلما كانت
            درجة الائتمان الخاصة بك أفضل ، كان التأمين على استثماراتك أفضل وقلت
            أقساط التأمين على هذا التأمين. بهذه الطريقة ، يمكنك تغطية أكبر قدر
            ممكن من المخاطر الخاصة بك وزيادة عائد الاستثمار المحتمل.
          </h3>
        </div>
      </>
    );
  }
}

export default InvestmentCalculator;
