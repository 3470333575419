import React from "react";
import { Helmet } from "react-helmet-async";
import "./ScientificCalculator.scss";
import { evaluate, string } from "mathjs";

class ScientificCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      equation: "",
      history: [],
    };
    this.handleEquationChange = this.handleEquationChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSolve = this.handleSolve.bind(this);
    this.updateState = this.updateState.bind(this);
    this.clearEquation = this.clearEquation.bind(this);
    this.bsEquation = this.bsEquation.bind(this);
  }

  updateState(newState) {
    this.setState(newState);
  }

  handleEquationChange(e) {
    // console.log(e.target.name)
    var copyHistory = [...this.state.history];
    this.updateState({ equation: e.target.name, history: copyHistory });
    // console.log(this.state.equation)
  }

  handleClick(e) {
    var focus = document.getElementById("mainInput");
    focus.click();
    var copyEquation = [...this.state.equation];
    copyEquation.push(e.target.name);
    copyEquation = copyEquation.join("");
    console.log(copyEquation);
    var copyHistory = [...this.state.history];
    this.updateState({ equation: copyEquation, history: copyHistory });
    // console.log(e.target.name)
  }

  handleSolve() {
    var copyEquation = this.state.equation;
    var copyHistory = [...this.state.history];
    console.log("history:", this.state.history);
    var res = string(evaluate(copyEquation));
    copyHistory.push(copyEquation + "=" + res);
    // console.log(copyHistory)
    this.updateState({
      equation: res,
      history: copyHistory,
    });
  }

  clearEquation() {
    var copyHistory = this.state.history;
    if (this.state.equation === "") {
      copyHistory = [];
    }
    this.updateState({ equation: "", history: copyHistory });
  }

  bsEquation() {
    var copyEquation = [...this.state.equation];
    copyEquation = copyEquation.slice(0, copyEquation.length - 1).join("");
    var copyHistory = this.state.history;
    this.updateState({ equation: copyEquation, history: copyHistory });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>آلة حاسبة علمية</title>
          <meta
            name="description"
            content="الآلة الحاسبة العلمية هي جهاز يوفر حسابات رياضية متقدمة."
          />
          <link rel="canonical" href="/ScientificCalculator" />
        </Helmet>
        <div className="main-app">
          <div className="scientificCalculator">
            <h2>آلة حاسبة علمية</h2>
            <input
              className="input"
              id="mainInput"
              type={"text"}
              defaultValue={this.state.equation}
              onChange={this.handleEquationChange}
            />
            <div className="numpad">
              <button className="calcBtn" name="pi" onClick={this.handleClick}>
                &pi;
              </button>
              <button
                className="calcBtn"
                name="abs("
                onClick={this.handleClick}
              >
                abs
              </button>
              <button
                className="calcBtn"
                name="exp("
                onClick={this.handleClick}
              >
                exp
              </button>
              <button className="calcBtn" onClick={this.clearEquation}>
                C
              </button>
              <button className="calcBtn" onClick={this.bsEquation}>
                AC
              </button>
              <button
                className="calcBtn"
                name="sin("
                onClick={this.handleClick}
              >
                sin
              </button>
              <button className="calcBtn" name="(" onClick={this.handleClick}>
                (
              </button>
              <button className="calcBtn" name=")" onClick={this.handleClick}>
                )
              </button>
              <button
                className="calcBtn"
                name="factorial("
                onClick={this.handleClick}
              >
                !
              </button>
              <button className="calcBtn" name="/" onClick={this.handleClick}>
                /
              </button>
              <button
                className="calcBtn"
                name="cos("
                onClick={this.handleClick}
              >
                cos
              </button>
              <button className="calcBtn" name="7" onClick={this.handleClick}>
                7
              </button>
              <button className="calcBtn" name="8" onClick={this.handleClick}>
                8
              </button>
              <button className="calcBtn" name="9" onClick={this.handleClick}>
                9
              </button>
              <button className="calcBtn" name="*" onClick={this.handleClick}>
                X
              </button>
              <button
                className="calcBtn"
                name="tan("
                onClick={this.handleClick}
              >
                tan
              </button>
              <button className="calcBtn" name="4" onClick={this.handleClick}>
                4
              </button>
              <button className="calcBtn" name="5" onClick={this.handleClick}>
                5
              </button>
              <button className="calcBtn" name="6" onClick={this.handleClick}>
                6
              </button>
              <button className="calcBtn" name="-" onClick={this.handleClick}>
                &#8722;
              </button>
              <button
                className="calcBtn"
                name="log("
                onClick={this.handleClick}
              >
                log
              </button>
              <button className="calcBtn" name="1" onClick={this.handleClick}>
                1
              </button>
              <button className="calcBtn" name="2" onClick={this.handleClick}>
                2
              </button>
              <button className="calcBtn" name="3" onClick={this.handleClick}>
                3
              </button>
              <button className="calcBtn" name="+" onClick={this.handleClick}>
                +
              </button>
              <button className="calcBtn" name="ln(" onClick={this.handleClick}>
                ln
              </button>
              <button className="calcBtn" name="^" onClick={this.handleClick}>
                x^y
              </button>
              <button className="calcBtn" name="0" onClick={this.handleClick}>
                0
              </button>
              <button className="calcBtn" name="." onClick={this.handleClick}>
                .
              </button>
              <button className="calcBtn equal" name="=" onClick={this.handleSolve}>
                =
              </button>
            </div>
          </div>
          <h3>
            <p>
              تُعَدُّ آلة الحاسبة العلمية واحدة من الأدوات الضرورية التي تُساعد
              الطلاب والعلماء والمهندسين والمبرمجين في إجراء حسابات دقيقة
              ومعقدة. تتميز هذه الآلة بمجموعة واسعة من الوظائف الرياضية
              والعلمية، مثل الجذور التربيعية والتكامل والتفاضل والمعادلات
              والوحدات والتحويلات والإحصاءات، مما يجعلها أداة مثالية للتطبيقات
              العلمية والهندسية المتقدمة.
            </p>
            <p>
              تستخدم الآلة الحاسبة العلمية طرقًا وخوارزميات معقدة لتنفيذ
              العمليات الحسابية الدقيقة. وتعتمد على تقنيات برمجية متطورة تستخدم
              الرياضيات والفيزياء والهندسة والإحصاءات وعلم الكمبيوتر لتحقيق دقة
              عالية في الحسابات. يتم تنفيذ هذه الخوارزميات داخل وحدة المعالجة
              المركزية للآلة الحاسبة، حيث يتم تطبيق خوارزميات التحليل الرقمي
              لتنفيذ العمليات الحسابية بشكل سريع وفعال.
            </p>
            <p>
              تعتبر الآلة الحاسبة العلمية أداة قوية للمستخدمين الذين يحتاجون إلى
              إجراء حسابات دقيقة في مجالات متنوعة. فهي توفر ميزات متقدمة مثل
              ذاكرة للمتغيرات والوظائف والمعادلات، والقدرة على العمل مع نظام
              الأعداد الحقيقية والمركبة والمصفوفات والأعداد العشرية العائمة،
              بالإضافة إلى القدرة على حساب التفاضل والتكامل والتحويلات
              والإحصاءات بطرق دقيقة.
            </p>
            <p>
              ان تقنية الآلة الحاسبة العلمية متقدمة ومعقدة، حيث تعتمد على العديد
              من الخوارزميات والتقنيات البرمجية المتطورة. يتم تنفيذ هذه
              الخوارزميات باستخدام لغات البرمجة المناسبة مثل C++ وJava وPython
              وغيرها. تعتمد هذه اللغات على تقنيات الحساب العددي وتحليل البيانات
              والبرمجة المتقدمة لتنفيذ العمليات الحسابية بطريقة فعالة ودقيقة.
            </p>
            <p>
              تعتبر آلة الحاسبة العلمية أداة لا غنى عنها في مجالات العلم
              والهندسة والرياضيات والفيزياء والكيمياء وغيرها من العلوم
              التطبيقية. فهي تساعد الباحثين والطلاب والمهندسين في إجراء حسابات
              دقيقة وتنفيذ التحليلات العددية وحل المعادلات والمشاكل الرياضية
              المعقدة. كما أنها توفر لهم القدرة على استكشاف العلاقات الرياضية
              وتجربة أفكار جديدة واختبار فروضياتهم بطريقة سهلة وسريعة.
            </p>
            <p>
              باستخدام الآلة الحاسبة العلمية، يمكن للمستخدمين حل المعادلات
              التفاضلية والتكاملية التي تعد أساسية في العديد من المجالات العلمية
              والهندسية. كما يمكنهم استخدامها لإجراء التحليلات الإحصائية المعقدة
              والتي تلعب دورًا هامًا في البحث وتحليل البيانات. بفضل وظائف الرسم
              البياني المتقدمة، يمكن للمستخدمين أيضًا تصور النتائج وتمثيلها بشكل
              بصري، مما يسهل فهم البيانات وتوضيح العلاقات الرياضية.
            </p>
          </h3>
        </div>
      </>
    );
  }
}

export default ScientificCalculator;
