import { Outlet, Link } from "react-router-dom";
import "./Navbar.scss";
import { useState } from 'react';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className={`navbar ${isOpen ? 'open' : ''}`}>
        <div className="navbar__hamburger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
          <h2 className="header">
            <a href="/">آلات حاسبة</a>
          </h2>
        <ul className="navbar__list">
          <li className="navbar__item">
            <Link  onClick={toggleMenu} to="/Calculator">آلة حاسبة</Link>
          </li>
          <li className="navbar__item">
            <Link  onClick={toggleMenu} to="/ScientificCalculator">آلة حاسبة علمية</Link>
          </li>
          <li className="navbar__item">
            <Link  onClick={toggleMenu} to="/InvestmentCalculator">حاسبة الاستثمار</Link>
          </li>
          <li className="navbar__item">
            <Link  onClick={toggleMenu} to="/BmiCalculator">حاسبة مؤشر كتلة الجسم BMI</Link>
          </li>
          <li className="navbar__item">
            <Link  onClick={toggleMenu} to="/كيف احسب عمري">حساب العمر</Link>
          </li>
          <li className="navbar__item">
            <Link  onClick={toggleMenu} to="/NetSpeed"> قياس سرعة الإنترنت</Link>
          </li>{" "}
          {/* <li className="navbar__item">
            <Link  onClick={toggleMenu} to="/CurrencyConversion"> تحويل عملات</Link>
          </li> */}
          <li className="navbar__item">
            <Link  onClick={toggleMenu} to="/News">اخبار</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </>
  );
};

export default NavBar;