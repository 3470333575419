import React from "react";
import { Helmet } from "react-helmet-async";
import "./NetSpeed.scss";

class NetSpeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: "",
    };
    this.check = this.check.bind(this);
  }

  check() {
    const imageAddr = "https://cdn.wallpapersafari.com/42/1/Scu4IV.jpg";
    const downloadSize = 18300000;

    const NewlineText = (text) => {
      return text.split("\n").map((str) => <p>{str}</p>);
    };

    const ShowProgressMessage = (msg) => {
      if (console) {
        if (typeof msg == "string") {
          console.log(msg);
        } else {
          var text = "";
          for (var i = 0; i < msg.length; i++) {
            console.log(msg[i]);
            text += msg[i] + "\n";
          }
          var newText = NewlineText(text);
          console.log(newText);
          this.setState({ speed: newText });
        }
      }

      var oProgress = document.getElementById("progress");
      if (oProgress) {
        var actualHTML = typeof msg == "string" ? msg : msg.join("<br />");
        oProgress.innerHTML = actualHTML;
      }
    };

    const InitiateSpeedDetection = () => {
      const button = document.getElementById("check");
      button.classList.add("loading");
      button.classList.remove("checkBtn");
      window.setTimeout(MeasureConnectionSpeed, 1);
    };

    InitiateSpeedDetection();

    if (window.addEventListener) {
      window.addEventListener("load", InitiateSpeedDetection, false);
    } else if (window.attachEvent) {
      window.attachEvent("onload", InitiateSpeedDetection);
    }

    function MeasureConnectionSpeed() {
      var startTime, endTime;
      var download = new Image();
      download.onload = function () {
        endTime = new Date().getTime();
        showResults();
      };

      download.onerror = () => {
        ShowProgressMessage("Invalid image, or error downloading");
      };

      startTime = new Date().getTime();
      var cacheBuster = "?nnn=" + startTime;
      download.src = imageAddr + cacheBuster;

      function showResults() {
        const button = document.getElementById("check");
        var duration = (endTime - startTime) / 1000;
        var bitsLoaded = downloadSize * 8;
        var speedBps = (bitsLoaded / duration).toFixed(2);
        var speedKbps = (speedBps / 1024).toFixed(2);
        var speedMbps = (speedKbps / 1024).toFixed(2);
        button.classList.remove("loading");
        ShowProgressMessage([
          "سرعة اتصالك هي:",
          speedBps + " bps",
          speedKbps + " kbps",
          speedMbps + " Mbps",
        ]);
        button.classList.add("checkBtn");
      }
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>سرعة الإنترنت</title>
          <meta name="description" content=" قياس سرعة الإنترنت" />
          <link rel="canonical" href="/NetSpeed" />
        </Helmet>
        <div className="main-app">
          <div className="netSpeed">
            <button onClick={this.check} className="checkBtn" id="check">
              فحص
            </button>
            {this.state.speed}
          </div>
          <h3>
            <h1> قياس سرعة الإنترنت </h1>
            <p>
              قياس سرعة الإنترنت هو عملية تحديد سرعة اتصالك بشبكة الإنترنت.
              تعتبر سرعة الإنترنت عاملًا حاسمًا في تجربة التصفح واستخدام الخدمات
              الرقمية. تعتمد قياسات سرعة الإنترنت على عدة عوامل، بما في ذلك سرعة
              التحميل وسرعة التنزيل وتأخير الشبكة.
            </p>
            <p>
              لقياس سرعة الإنترنت، يتم استخدام أدوات القياس المتاحة عبر
              الإنترنت. تقوم هذه الأدوات بإرسال طلبات صغيرة إلى خادم واحد أو عدة
              خوادم عبر الإنترنت، وتقيس الوقت اللازم للطلبات للوصول إلى الخادم
              والعودة. بناءً على هذه القياسات، يتم حساب سرعة التحميل وسرعة
              التنزيل وتأخير الشبكة.
            </p>
            <p>
              تساعد قياسات سرعة الإنترنت في تقدير جودة اتصالك بالإنترنت ومعرفة
              ما إذا كانت سرعة الاتصال تلبي احتياجاتك. قد يساهم تحديد سرعة
              الإنترنت في اتخاذ قرارات متعلقة بترقية الخدمة أو تغيير مزود الخدمة
              إذا كانت السرعة غير مرضية.
            </p>
            <p>
              هناك العديد من الأدوات المتاحة عبر الإنترنت لقياس سرعة الإنترنت،
              ويمكن الوصول إليها بسهولة من أجهزة الكمبيوتر والهواتف الذكية. تتيح
              لك هذه الأدوات إجراء اختبارات سرعة فورية لاتصالك بالإنترنت.
            </p>
            <p>
              عند قياس سرعة التحميل، تقوم الأدوات بتحميل ملف صغير من الخادم
              وقياس الوقت اللازم لإكمال التحميل. وبناءً على الحجم المحمل والوقت
              المستغرق، يتم حساب سرعة التحميل بوحدة البت في الثانية (bps) أو
              بوحدات أكبر مثل كيلوبت في الثانية (Kbps) أو ميجابت في الثانية
              (Mbps).
            </p>
            <p>
              أما عن قياس سرعة التنزيل، فتعمل الأدوات على تحميل ملف كبير من
              الخادم وقياس الوقت اللازم لإكمال التحميل. بناءً على الحجم المحمل
              والوقت المستغرق، يتم حساب سرعة التنزيل بنفس الوحدات المذكورة
              سابقًا.
            </p>
            <p>
              بالإضافة إلى ذلك، تقيس الأدوات أيضًا تأخير الشبكة، المعروف أيضًا
              باسم "Ping". يتم ذلك من خلال إرسال طلب صغير إلى الخادم وقياس الوقت
              الذي يستغرقه الطلب للوصول إلى الخادم والعودة. يتم تعبير التأخير
              بوحدة الزمن المستغرق في الجزء الواحد من الثانية (مللي ثانية).
            </p>
            <p>
              قد يتأثر قياس سرعة الإنترنت بعوامل متعددة. من بين هذه العوامل،
              سرعة اتصالك بمزود الخدمة الإنترنت، وجود عوائق في الشبكة مثل
              الجدران الكثيفة أو التداخلات اللاسلكية، وحالة أجهزة التوجيه
              والتبديل في شبكتك المحلية.
            </p>
            <p>
              للحصول على نتائج قياس سرعة الإنترنت الموثوقة ودقيقة، يُفضل إجراء
              الاختبار عدة مرات في فترات مختلفة من اليوم للحصول على قراءات
              متوسطة. يُفضل أيضًا توصيل جهاز الكمبيوتر أو الجهاز الذي تجري
              الاختبارات عليه مباشرةً بالمودم للحصول على نتائج أكثر دقة.{" "}
            </p>
            <p>
              بالإضافة إلى قياس سرعة الإنترنت، يمكن أن توفر بعض الأدوات معلومات
              إضافية مفيدة. فقد تعرض بعض الأدوات مخططات توضح السرعة على مدار
              فترة زمنية معينة، مما يساعدك على معرفة تقلبات السرعة وفهم أفضل
              لأداء اتصالك بالإنترنت.
            </p>
            <p>
              علاوة على ذلك، يمكن أن تقدم بعض الأدوات توصيات لتحسين سرعة
              الإنترنت إذا كانت النتائج غير مرضية. قد تشمل هذه التوصيات تغيير
              موقع جهاز التوجيه اللاسلكي، تحديث برامج التشغيل أو تثبيت تحديثات
              النظام الأخيرة، أو الاتصال بمزود الخدمة للحصول على دعم فني.
            </p>
            <p>
              باختصار، قياس سرعة الإنترنت هو أداة مهمة لتقييم أداء اتصالك
              بالإنترنت. يمكنك استخدام الأدوات المتاحة عبر الإنترنت لتحديد سرعة
              التحميل وسرعة التنزيل وتأخير الشبكة. من خلال فهم سرعة اتصالك،
              يمكنك اتخاذ القرارات المناسبة بشأن خدمة الإنترنت وتحسين تجربتك على
              الإنترنت بشكل عام.
            </p>
          </h3>
        </div>
      </>
    );
  }
}

export default NetSpeed;
