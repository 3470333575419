import React from "react";
import { Helmet } from "react-helmet-async";
import "./BmiCalculator.css";

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bmi: "",
      info: "",
      height: "",
      weight: "",
    };
    this.handleBmi = this.handleBmi.bind(this);
  }

  handleBmi = () => {
    let val = (
      [
        Number(this.state.weight) /
          Number(this.state.height) /
          Number(this.state.height),
      ] * 10000
    ).toFixed(1);
    this.setState({ bmi: val });
    if (val < 18.5) {
      this.setState({ info: "تحت الوزن" });
    } else if (val > 18.5 && val <= 24.9) {
      this.setState({ info: "صحي" });
    } else if (val > 24.9 && val < 30) {
      this.setState({ info: "وزن زائد عن المطلوب" });
    } else {
      this.setState({ info: "سمين" });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>حاسبة مؤشر كتلة الجسم BMI</title>
          <meta
            name="description"
            content="مؤشر كتلة الجسم (BMI) هو ناتج تناسبي (نسبة معينة من الوزن صرف الطول مربعاً)"
          />
          <link rel="canonical" href="/BmiCalculator" />
        </Helmet>
        <div className="main-app">
          <div>
            <h2>حاسبة مؤشر كتلة الجسم BMI</h2>
            <input
              type="text"
              onChange={(e) => this.setState({ height: e.target.value })}
              placeholder="الارتفاع في سم"
            />
            <input
              type="text"
              onChange={(e) => this.setState({ weight: e.target.value })}
              placeholder="الوزن بالكيلوجرام"
            />
            <button onClick={this.handleBmi}>حسب</button>
            <h1>{this.state.bmi}</h1>
            <h2>{this.state.info}</h2>
          </div>
          <h3>
            <p>
              في عالمنا الحديث، أصبحت الصحة واللياقة البدنية أمورًا مهمة جدًا.
              واحدة من الأدوات المفيدة لقياس الصحة العامة للجسم هي مؤشر كتلة
              الجسم (Body Mass Index - BMI).
            </p>
            <p>
              يستخدم مؤشر كتلة الجسم BMI لتقدير الدهون في الجسم وتصنيفها بناءً
              على الوزن والطول. على الرغم من أن مؤشر كتلة الجسم لا يعتبر قياسًا
              دقيقًا للدهون في الجسم بشكل فردي، إلا أنه يقدم معلومات قيمة لتقييم
              الوضع الصحي العام للفرد.
            </p>
            <h2>كيفية حساب مؤشر كتلة الجسم BMI:</h2>
            <p>
              يتم حساب مؤشر كتلة الجسم بالقسمة بين وزن الشخص بالكيلوجرام ومربع
              الطول بالأمتار. وفيما يلي الصيغة الرياضية لحساب BMI:
            </p>
            <code>BMI = الوزن (كجم) ÷ (الطول (م) × الطول (م))</code>
            <p>
              بعد حساب القيمة الناتجة، يمكن تصنيف الفرد طبقًا للنطاقات العامة
              التالية:
            </p>
            <ul>
              <li>أقل من 18.5: الوزن الناقص (Underweight)</li>
              <li>ما بين 18.5 و 24.9: وزن طبيعي (Normal weight)</li>
              <li>ما بين 25 و 29.9: الوزن الزائد (Overweight)</li>
              <li>
                ما بين 30 و 34.9: السمنة المفرطة من الدرجة الأولى (Obese Class
                1)
              </li>
              <li>
                ما بين 35 و 39.9: السمنة المفرطة من الدرجة الثانية (Obese Class
                2)
              </li>
              <li>
                40 وأكثر: السمنة المفرطة من الدرجة الثالثة (Obese Class 3)
              </li>
            </ul>
            <h2>كيفية استخدام حاسبة مؤشر كتلة الجسم BMI:</h2>
            <p>
              لتسهيل حساب مؤشر كتلة الجسم، يمكنك استخدام حاسبة BMI التي توفرها
              العديد من المواقع والتطبيقات الصحية. قد تحتاج إلى إدخال الوزن
              والطول وبعض المعلومات الأخرى، ثم يتم حساب مؤشر كتلة الجسم
              تلقائيًا.
            </p>
            <p>وفيما يلي خطوات بسيطة لاستخدام حاسبة مؤشر كتلة الجسم:</p>
            <ol>
              <li>
                قم بفتح حاسبة مؤشر كتلة الجسم BMI على الموقع أو التطبيق الذي
                تفضله.
              </li>
              <li>أدخل وزنك في الوحدة المناسبة (كجم أو رطل).</li>
              <li>أدخل طولك في الوحدة المناسبة (أمتار أو بوصة).</li>
              <li>انقر على زر "احسب" أو ما شابه ذلك.</li>
              <li>سيتم عرض قيمة مؤشر كتلة الجسم BMI وتصنيف الوزن الخاص بك.</li>
            </ol>
            <h2>تحليل مؤشر كتلة الجسم BMI:</h2>
            <p>
              توفر مؤشر كتلة الجسم BMI تقديرًا سريعًا للصحة العامة للجسم، ولكن
              هناك بعض النقاط التي يجب مراعاتها:
            </p>
            <ul>
              <li>
                قد لا يكون BMI دقيقًا للأشخاص ذوي البنية العضلية الكبيرة، حيث
                يمكن أن يزن العضلات أكثر من الدهون رغم وجود وزن صحي.
              </li>
              <li>
                لا ينبغي الاعتماد فقط على قيمة BMI في تقييم الصحة العامة. يجب أن
                يتم مراجعة العوامل الأخرى مثل النشاط البدني، ونمط الحياة، وتاريخ
                الأمراض، وغيرها.
              </li>
            </ul>
            <h2>الاستنتاج:</h2>
            <p>
              تعد حاسبة مؤشر كتلة الجسم BMI أداة مفيدة لتقدير الصحة العامة
              للجسم. بناءً على الوزن والطول، يمكن استخدامها لتصنيف الوزن والتحقق
              مما إذا كان الشخص يعاني من الوزن الناقص، الوزن الطبيعي، الوزن
              الزائد أو السمنة.
            </p>
            <p>
              ومع ذلك، يجب أن يتم استخدام مؤشر كتلة الجسم BMI كأداة إرشادية فقط،
              ولا ينبغي الاعتماد عليها بشكل حصري في تقييم الصحة. من الأفضل أن
              يتم مراجعة الحالة الصحية مع الأطباء المختصين والاستشاريين للحصول
              على توجيه ونصائح شاملة لتحسين الحالة الصحية بشكل عام.
            </p>
            <p>
              نأمل أن يكون هذا المقال قد قدم لك فهمًا أفضل لحاسبة مؤشر كتلة
              الجسم BMI وكيفية استخدامها وتحليلها. تذكر دائمًا أن الصحة الجيدة
              هي ثروة حقيقية، ويجب علينا العناية بأجسامنا والسعي للحفاظ على نمط
              حياة صحي ونشط.
            </p>
          </h3>
        </div>
      </>
    );
  }
}

export default Calculator;
